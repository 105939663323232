import type { SVGProps } from "react";
const IconAlarmEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 81 80" {...props}>
    <path
      fill="currentColor"
      d="M59.322 11.18a1.67 1.67 0 0 1 0-2.357c.65-.65 1.706-.65 2.356 0l6.667 6.666c.65.65.65 1.707 0 2.357a1.674 1.674 0 0 1-2.36 0zM15.014 8.82a1.67 1.67 0 0 0-2.357 0l-6.667 6.666c-.65.65-.65 1.707 0 2.357a1.674 1.674 0 0 0 2.36 0l6.667-6.667a1.67 1.67 0 0 0-.003-2.356M37.168 65c-13.786 0-25-11.214-25-25 0-13.787 11.214-25 25-25 12.597 0 23.267 9.403 24.814 21.873a1.66 1.66 0 0 0 1.86 1.45 1.67 1.67 0 0 0 1.45-1.86C63.538 22.326 51.448 11.67 37.168 11.67c-15.623 0-28.333 12.71-28.333 28.333 0 9.76 4.963 18.383 12.497 23.48l-5.34 5.34c-.65.65-.65 1.707 0 2.357a1.674 1.674 0 0 0 2.36 0l5.963-5.964a28.1 28.1 0 0 0 12.856 3.12A1.667 1.667 0 1 0 37.168 65m0-39.867c-.92 0-1.666.747-1.666 1.667v13.333a1.667 1.667 0 0 0 3.333 0V26.8c0-.92-.747-1.667-1.667-1.667m38.333 27.804a4.6 4.6 0 0 1-1.363 3.276L55.751 74.516c-.313.31-.736.487-1.176.487h-7.407c-.92 0-1.666-.747-1.666-1.667V65.93c0-.44.176-.864.486-1.177l18.303-18.387a4.59 4.59 0 0 1 3.273-1.363h.008a4.6 4.6 0 0 1 3.27 1.356l3.3 3.3a4.6 4.6 0 0 1 1.36 3.278m-8.786 5.962-5.113-5.11-12.767 12.823v5.054h5.057zm5.453-5.967c0-.346-.137-.672-.38-.92l-3.3-3.3a1.3 1.3 0 0 0-.913-.38h-.003c-.347 0-.67.138-.914.38l-2.7 2.714 5.123 5.124 2.71-2.7c.24-.244.377-.57.377-.918"
    />
  </svg>
);
export default IconAlarmEdit;
