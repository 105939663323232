import type { SVGProps } from "react";
const IconStoreRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m21.545 7.871-1.039-3.072c-.489-1.444-1.094-2.548-4.006-2.548h-9c-2.912 0-3.517 1.104-4.005 2.548L2.454 7.872c-.461 1.364-.118 2.816.796 3.772V18c0 2.418 1.332 3.75 3.75 3.75h10c2.418 0 3.75-1.332 3.75-3.75v-6.357c.914-.956 1.256-2.408.795-3.772m-17.67.481 1.041-3.073C5.26 4.261 5.433 3.75 7.5 3.75h9c2.067 0 2.24.511 2.585 1.529l1.039 3.072c.344 1.014-.02 2.104-.863 2.591-1.227.708-3.084.002-3.509-1.193a.75.75 0 0 0-.707-.499h-.003a.75.75 0 0 0-.706.504c-.296.853-1.3 1.496-2.336 1.496s-2.04-.643-2.336-1.496a.75.75 0 0 0-.706-.504c-.285.034-.604.199-.71.499-.304.855-1.313 1.501-2.349 1.501-.41 0-.812-.106-1.16-.308-.843-.486-1.207-1.576-.864-2.59M13.28 20.25h-2.5V17.5c0-.689.561-1.25 1.25-1.25s1.25.561 1.25 1.25zm3.72 0h-2.22V17.5a2.75 2.75 0 0 0-2.75-2.75 2.75 2.75 0 0 0-2.75 2.75v2.75H7c-1.577 0-2.25-.673-2.25-2.25v-5.446c1.47.432 3.206-.053 4.201-1.141A4.18 4.18 0 0 0 12 12.749a4.18 4.18 0 0 0 3.049-1.336 4.2 4.2 0 0 0 3.052 1.336 3.8 3.8 0 0 0 1.149-.183V18c0 1.577-.673 2.25-2.25 2.25"
    />
  </svg>
);
export default IconStoreRegular;
