import { RefreshTokenRequestErrors } from "@/generated/requests/services";
import * as requests from "@/static/lib/old-login-requests";
import { track, trackUser } from "@/static/lib/tracking";
import { determineRegion, fetchLocal, storeLocal } from "@/static/lib/util";
import { setToken } from "@/static/lib/xhr_legacy";
import { datadogRum } from "@datadog/browser-rum";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { useUserTrackingConsentContext } from "../UserTrackingConsentContext/UserTrackingConsentContext";

const LS_CUSTOMER = "customer";

// a list of paths (regex) that require authentication, and the redirect path that should be used if the user logs out
const authenticatedPathsAndRedirects = [
  {
    path: new RegExp(/^\/gifting/),
    redirect: "/",
  },
];

export type CustomerType = {
  userId?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
};

export type CustomerContextType = {
  customer?: CustomerType;
  requestLoginCode: (phoneNumber: string) => Promise<{ requestId?: string; error?: string }>;
  validateLogin: (requestId: string, code: string) => Promise<{ success: boolean; error?: string }>;
  signOut: Function;
  loadingCustomer?: boolean;
};

const CustomerContext = createContext<CustomerContextType>(null);

export const CustomerContextProvider = ({ children }) => {
  const { locale, pathname, push } = useRouter();
  const [customer, setCustomer] = useState<CustomerType>();
  const [loadingCustomer, setLoadingCustomer] = useState(true);
  const { consent } = useUserTrackingConsentContext();

  const requestLoginCode = async (phoneNumber: string): Promise<{ requestId?: string; error?: string }> => {
    try {
      const { requestId, errors } = await requests.requestLoginCode(phoneNumber);
      if (errors) {
        return { error: getMessageForRefreshTokenRequestError(errors[0]) };
      }
      return { requestId };
    } catch (err) {
      if (err.networkError) {
        return { error: "a_network_error_occurred_please_check_your_connection_and_try_again" };
      } else {
        return { error: "an_unexpected_error_occurred_please_contact_support_if_the_error_persists" };
      }
    }
  };

  const validateLogin = async (requestId: string, code: string) => {
    try {
      const region = determineRegion(locale);
      const { accessToken, refreshToken } = await requests.validateLogin(requestId, code, region);
      if (!accessToken || !refreshToken) {
        return { success: false, error: "the_code_you_entered_is_incorrect_please_try_again" };
      }

      setToken(accessToken.token);
      const user = await requests.getCurrentUser();
      storeLocal(LS_CUSTOMER, user);
      setCustomer(user);
      // TODO: review post-login stuff – we shouldn't need all the extra local
      // storage keys since everything is stored on the customer object, but I'm
      // going to need to audit it before I can safely remove it.
      storeLocal("userId", user.userId);
      storeLocal("name", user.name);
      storeLocal("email", user.email);
      storeLocal("phoneNumber", user.phoneNumber);
      storeLocal("refreshToken", refreshToken);
      storeLocal("accessToken", accessToken);

      trackUser();
      track({
        event: "identify_user",
        ph: user.phoneNumber,
        em: user.email,
      });

      return { success: true };
    } catch (err) {
      if (err.networkError) {
        return { success: false, error: "a_network_error_occurred_please_check_your_connection_and_try_again" };
      } else {
        return { success: false, error: "an_unexpected_error_occurred_please_contact_support_if_the_error_persists" };
      }
    }
  };

  const signOut = () => {
    [
      LS_CUSTOMER,
      "accessToken",
      "chartToken",
      "giftcard-order",
      "name",
      "nextWeekWarning",
      "order",
      "phoneNumber",
      "refreshToken",
      "sundayWarning",
      "userId",
      "userLocation",
    ].map((v) => storeLocal(v, null));
    setToken(null);
    setCustomer(null);

    // see if we need to redirect the user somewhere
    const redirectPathMatch = authenticatedPathsAndRedirects.find((obj) => obj.path.test(pathname));
    if (redirectPathMatch) {
      push(redirectPathMatch.redirect);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const customer = fetchLocal<CustomerType>(LS_CUSTOMER);
      setCustomer(customer);
      setLoadingCustomer(false);
    }
  }, []);

  useEffect(() => {
    // Only record customer information to datadogRum if the user is logged in and has marketing consent
    if (customer && consent?.marketing) {
      datadogRum.setUser({
        id: customer.userId,
      });
    } else {
      datadogRum.setUser({
        id: null,
      });
    }
  }, [customer, consent]);

  return (
    <CustomerContext.Provider value={{ customer, requestLoginCode, validateLogin, signOut, loadingCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error("useCustomerContext must be used within CustomerContextProvider");
  }

  return context;
};

const getMessageForRefreshTokenRequestError = (error: RefreshTokenRequestErrors) => {
  switch (error) {
    case RefreshTokenRequestErrors.SmsInvalidPhoneNumber:
      return "the_phone_number_you_provided_is_invalid_please_try_a_different_number";
    case RefreshTokenRequestErrors.SmsTooManyCodes:
      return "you_have_reached_the_maximum_number_of_login_attempts_please_wait_a_few_minutes_and_try_again";
  }
};
