import {
  ContactDetailsDocument,
  DeleteAddressDocument,
  EditMyselfDocument,
  type LimitedUserUpsert,
  UpdateUserMetaDocument,
  UpsertAddressDocument,
  type UserAddressInput,
  UserAddressesDocument,
} from "@/generated/requests/backend";
import {
  CustomerAccountsDocument,
  CustomerPaymentMethodsDocument,
  CustomerReceiptHistoryDocument,
  EarnLoyaltyDocument,
  EarnLoyaltyType,
  GiftcardOrVoucherForAccountDocument,
  LoyaltyStatusDocument,
  MostRecentOrderDocument,
  TransferGiftcardDocument,
} from "@/generated/requests/pos";
import { Service, client } from "@/lib/apollo";
import dayjs from "dayjs";

const fetchInfo = async () => {
  try {
    const response = await client.query({
      query: ContactDetailsDocument,
      context: { service: Service.backend },
    });

    return response.data?.me;
  } catch (error) {
    console.error("Error retrieving info.", error);
  }
};

const saveInfo = async (edit: LimitedUserUpsert) => {
  const response = await client.mutate({
    mutation: EditMyselfDocument,
    variables: { edit },
    context: { service: Service.backend },
  });

  return response.data?.editMyself;
};

export const setDefaultAddress = async (defaultAddressId = "") => {
  const response = await client.mutate({
    mutation: UpdateUserMetaDocument,
    variables: { input: { defaultAddressId } },
    context: { service: Service.backend },
  });

  return response.data?.privateMyself?.updateUserMeta?.defaultAddressId;
};

export const deleteUserAddress = async (addressId = "") => {
  const response = await client.mutate({
    mutation: DeleteAddressDocument,
    variables: { addressId },
    context: { service: Service.backend },
  });

  return response.data?.privateMyself?.deleteAddress;
};

const fetchUserAddresses = async () => {
  const response = await client.query({
    query: UserAddressesDocument,
    context: { service: Service.backend },
  });

  const addresses = [...(response.data?.me?.addresses ?? [])];
  const defaultAddressId = response.data?.me?.defaultAddressId;

  return addresses
    .sort((a, b) => (a?.customerName || a?.name || "").localeCompare(b?.customerName || b.name || ""))
    .map((a) => ({
      ...a,
      isDefault: a?.addressId == defaultAddressId,
      formattedSubtitle: [a.city, a.state].filter((a) => a).join(", "),
    }));
};

const fetchHistory = async () => {
  try {
    const response = await client.query({
      query: CustomerReceiptHistoryDocument,
      context: { service: Service.pos },
    });

    const history = response.data?.customer?.customerReceipts ?? [];
    return [...history].sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));
  } catch (error) {
    console.error("Error retrieving receipts.", error);
  }
};

const fetchCards = async (stripeRegion: string) => {
  try {
    const response = await client.query({
      query: CustomerPaymentMethodsDocument,
      context: { service: Service.pos },
      variables: {
        stripeRegion: stripeRegion || "US",
      },
    });

    return response.data?.customer?.customer ?? { name: "", cards: null };
  } catch (error) {
    console.error("Error retrieving cards.", error);
  }
};

const saveAddress = async (upsert: UserAddressInput) => {
  try {
    const response = await client.mutate({
      mutation: UpsertAddressDocument,
      variables: { upsert },
      context: { service: Service.backend },
    });

    return response.data?.privateMyself?.upsertAddress;
  } catch (error) {
    console.error(error);
  }
};

const fetchOrder = async () => {
  const response = await client.query({
    query: MostRecentOrderDocument,
    context: { service: Service.pos },
  });

  return response.data?.customer?.myMostRecentOrder;
};

const fetchAccount = async () => {
  const response = await client.query({
    query: CustomerAccountsDocument,
    context: { service: Service.pos },
  });

  return {
    customer: response.data?.customer?.customer,
    config: response.data?.public?.loyalty?.config,
  };
};

const fetchGiftcardOrVoucher = async (type, code) => {
  const response = await client.query({
    query: GiftcardOrVoucherForAccountDocument,
    variables: { code },
    context: { service: Service.pos },
  });

  return response.data?.public.giftcardOrVoucherFromCode;
};

const transferGiftcard = async (giftcardId) => {
  const response = await client.mutate({
    mutation: TransferGiftcardDocument,
    variables: { input: giftcardId },
    context: { service: Service.pos },
  });

  return response.data?.customer;
};

const addLoyaltyPoints = async (type, metadata = null) => {
  try {
    const response = await client.mutate({
      mutation: EarnLoyaltyDocument,
      variables: { type, metadata },
      context: { service: Service.pos },
    });

    return response;
  } catch (errors) {
    return { errors };
  }
};

const fetchLoyaltyPoints = async () => {
  const response = await client.query({
    query: LoyaltyStatusDocument,
    context: { service: Service.pos },
  });

  return response.data?.customer?.customer?.earnedLoyaltyStatus?.status || [];
};

export {
  addLoyaltyPoints,
  EarnLoyaltyType,
  fetchAccount,
  fetchCards,
  fetchGiftcardOrVoucher,
  fetchHistory,
  fetchInfo,
  fetchLoyaltyPoints,
  fetchOrder,
  fetchUserAddresses,
  saveAddress,
  saveInfo,
  transferGiftcard,
};
