import { ImagesV2 } from "@/public/images/all";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import IconTimesFilled from "../../atoms/Icons/TimesFilled";
import Text from "../../atoms/Text/Text";
import { useUserTrackingConsentContext } from "../../contexts/UserTrackingConsentContext/UserTrackingConsentContext";
import Button from "../../molecules/Button/Button";

export interface UserTrackingConsentBannerProps {
  visible?: boolean;
  onShowPreferences?: Function;
  onDismiss?: Function;
}

export default function UserTrackingConsentBanner({
  visible = false,
  onShowPreferences,
}: UserTrackingConsentBannerProps) {
  const { t } = useTranslation(["common"]);
  const { updateConsent } = useUserTrackingConsentContext();

  const handleShowPreferences = () => {
    onShowPreferences?.();
  };

  const handleRejectNonEssential = () => {
    updateConsent({
      necessary: true,
      marketing: false,
    });
  };

  const handleAcceptAll = () => {
    updateConsent({
      necessary: true,
      marketing: true,
    });
  };

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          className="fixed bottom-0 left-0 right-0 bg-secondary-light p-4 sm:py-[30px] sm:px-[50px] z-[999]"
          style={{ boxShadow: "0 0 18px rgba(0,0,0,.2)" }}
        >
          <div className="flex justify-end">
            <button onClick={handleRejectNonEssential} className="focus:outline-none focus:ring-2 focus:ring-dark-blue">
              <IconTimesFilled className="size-5" />
            </button>
          </div>
          <div className="flex items-start gap-2 sm:gap-5">
            <div className="w-[30px] h-[30px] flex-shrink-0 sm:w-[60px] sm:h-[60px] mt-1">
              <Image src={ImagesV2.illustrationMilkChocolateChip} alt={t("illustration_milk_chocolate_chip_cookie")} />
            </div>

            <div className="flex flex-col gap-y-2">
              <Text variant="title3" as="h1">
                {t("use_cookies")}
              </Text>
              <div className="inline">
                <Trans
                  t={t}
                  i18nKey="use_cookies_text"
                  components={{
                    tcLink: <Link className="font-bold underline" href="/termsandconditions"></Link>,
                    ppLink: <Link className="font-bold underline" href="/privacy"></Link>,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-wrap sm:flex-row gap-2 sm:gap-4 mt-4 sm:ml-[60px] sm:pl-5">
            <Button variant="secondary" className="w-full sm:w-fit" onClick={handleShowPreferences}>
              {t("cookie_settings")}
            </Button>
            <Button variant="secondary" className="w-full sm:w-fit" onClick={handleRejectNonEssential}>
              {t("reject_non_essential")}
            </Button>
            <Button onClick={handleAcceptAll} className="w-full sm:w-fit">
              {t("accept_all_cookies")}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
