// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// import "firebase/remote-config";
import "firebase/analytics";

// this is for analytics only
const firebaseConfig = {
  apiKey: "AIzaSyCQYaW1oKnqWSQxlglVkq8nEpkPE7UOsA8",
  authDomain: "my-project-1523938120250.firebaseapp.com",
  databaseURL: "https://my-project-1523938120250.firebaseio.com",
  projectId: "my-project-1523938120250",
  storageBucket: "my-project-1523938120250.appspot.com",
  messagingSenderId: "272765177112",
  appId: "1:272765177112:web:2843dce61aa68d6f7c226d",
  measurementId: "G-R2NZKG7V8K"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

let analytics = null;
if (typeof window !== "undefined") {
  analytics = firebase.analytics();
}

const logPurchase = (orderId, currency, total, tax) => {
  const totalValue = ((total || 0) * 0.01).toFixed(2);
  const taxValue = ((tax || 0) * 0.01).toFixed(2);
  analytics?.logEvent(firebase.analytics.EventName.PURCHASE, {
    transaction_id: orderId,
    currency: currency,
    value: totalValue,
    tax: taxValue
  });
};

export { analytics, firebase, logPurchase };
