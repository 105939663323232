const localStorageAvailable = (funcName = "localStorage") => {
  try {
    const func = window[funcName];
    const inWindow = typeof func === "object" && typeof func.setItem === "function";
    if (!inWindow) {
      return false;
    }
  } catch (e) {
    return false;
  }
  // Safari private mode has localStorage in the window, but throws when `setItem` is called
  const key = "safeLocalStorageTest";
  try {
    const func = window[funcName];
    func.setItem(key, "succeeds");
    func.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

const noop = () => {};

const useStorageWhenAvailable = (nativeMethod, args, callback = noop) => {
  if (localStorageAvailable()) {
    return window.localStorage[nativeMethod](...args);
  } else {
    callback();
  }
};

const safeLocalStorage = {
  get: (key, onLocalStorageNotAvailable) => {
    return useStorageWhenAvailable("getItem", [key], onLocalStorageNotAvailable);
  },
  set: (key, value, onLocalStorageNotAvailable) => {
    useStorageWhenAvailable("setItem", [key, value], onLocalStorageNotAvailable);
  },
  remove: (key, onLocalStorageNotAvailable) => {
    useStorageWhenAvailable("removeItem", [key], onLocalStorageNotAvailable);
  },
  removeAll: (onLocalStorageNotAvailable) => {
    useStorageWhenAvailable("clear", [], onLocalStorageNotAvailable);
  }
};

export default safeLocalStorage;

export const safeSessionStorage = localStorageAvailable("sessionStorage")
  ? {
      get(key, value) {
        const item = window.sessionStorage.getItem(key, value);
        if (!item) {
          return null;
        }
        try {
          return JSON.parse(item);
        } catch (error) {
          return item;
        }
      },
      set(key, value) {
        return window.sessionStorage.setItem(key, JSON.stringify(value));
      },
      remove(key) {
        return window.sessionStorage.removeItem(key);
      }
    }
  : {
      get: noop,
      set: noop,
      remove: noop
    };
