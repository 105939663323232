import classNames from "classnames";
import { HTMLAttributes, ReactNode, createElement } from "react";

const variants = {
  display1:
    "font-extrabold text-[55px] leading-[65px] lg:text-[80px] lg:leading-[90px] 2xl:text-[100px] leading-[110px]",
  display2:
    "font-extrabold text-[44px] leading-[50px] lg:text-[60px] lg:leading-[66px] 2xl:text-[75px] 2xl:leading-[80px]",
  display3:
    "font-extrabold text-[34px] leading-[40px] lg:text-[46px] lg:leading-[46px] 2xl:text-[55px] 2xl:leading-[65px]",
  display4:
    "font-extrabold text-[28px] leading-[32px] lg:text-[32px] lg:leading-[36px] 2xl:text-[36px] 2xl:leading-[40px]",
  title1: "font-bold text-[24px] leading-[30px] 2xl:text-[28px] 2xl:leading-[32px]",
  title2: "font-bold text-[20px] leading-[24px] 2xl:text-[22px] 2xl:leading-[26px]",
  title3: "font-bold text-[18px] leading-[20px] 2xl:text-[20px] 2xl:leading-[22px]",
  lineItem: "font-bold text-[16px] leading-[20px] 2xl:text-[18px] 2xl:leading-[22px]",
  body1: "font-normal text-[16px] leading-[20px] 2xl:text-[18px] 2xl:leading-[22px]",
  body2: "font-normal text-[14px] leading-[18px] 2xl:text-[16px] 2xl:leading-[20px]",
  smallHeader: "font-bold text-[14px] leading-[18px] 2xl:text-[16px] 2xl:leading-[20px]",
  finePrint: "font-normal text-[13px] leading-[15px] 2xl:text-[14px] 2xl:leading-[16px]",
  caption: "font-normal text-xs leading-4",
  caption2: "font-normal text-[0.815rem] leading-4",
  caption3: "font-bold text-[0.815rem] leading-4"
} as const;

export type TextVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as TextVariant[];

export interface TextProps<T extends keyof JSX.IntrinsicElements = "p"> {
  as?: T;
  variant?: TextVariant;
  children?: ReactNode;
}

export default function Text<T extends keyof JSX.IntrinsicElements>({
  as,
  variant = "body1",
  className,
  children,
  ...props
}: TextProps<T> & Omit<HTMLAttributes<JSX.IntrinsicElements[T]>, "as">) {
  const element = as || "p";
  return createElement(
    element,
    {
      className: classNames(variants[variant], className),
      ...props
    },
    children
  );
}
