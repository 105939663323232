import type { SVGProps } from "react";
const IconPenEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m20.944 5.284-2.228-2.228a2.67 2.67 0 0 0-1.948-.806 2.73 2.73 0 0 0-1.945.81L2.469 15.471A.75.75 0 0 0 2.25 16v5c0 .414.336.75.75.75h5a.75.75 0 0 0 .529-.218L20.94 9.177c.522-.519.81-1.21.811-1.945a2.73 2.73 0 0 0-.806-1.948M7.69 20.25H3.75v-3.94l8.993-9.034 3.982 3.981zM19.882 8.114l-2.094 2.085-3.987-3.986 2.085-2.095c.236-.237.55-.367.885-.368h.001c.334 0 .648.13.885.366l2.228 2.228a1.247 1.247 0 0 1-.003 1.77"
    />
  </svg>
);
export default IconPenEdit;
