import axios from "axios";
import { fetchLocal, storeLocal } from "./util";

let baseUrl = () => {
  return process.env.NEXT_PUBLIC_CRUMBL_API;
};

export const getJwtTokenPayload = (token: string) => {
  const payload = token.split(".")[1];
  const result = JSON.parse(atob(payload));

  if (typeof result === "string") {
    return {
      type: "LEGACY",
      token,
      result
    };
  }

  return {
    type: "REFRESH",
    token,
    result
  };
};

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "apollographql-client-name": "www"
};

const crumbl = axios.create({
  baseURL: baseUrl(),
  timeout: 30 * 1000,
  headers: {
    ...defaultHeaders
  }
});

let post = (path, body, cancel: any = {}) => {
  return crumbl.post(path, body, { cancelToken: cancel.token });
};

let get = (path, cancel: any = {}) => {
  return crumbl.get(path, { cancelToken: cancel.token });
};

let request = (config) => {
  return crumbl.request(config);
};

let log = (object) => {
  return crumbl.post("/log", object);
};

/* tokens */
let token = null;
let getToken = () => {
  if (token) {
    return token;
  }
  token = fetchLocal("token") || "";
  setTokenIfInParams();
  return token;
};

let setTokenIfInParams = () => {
  if (token) {
    return;
  }

  let pieces = [];
  if (typeof window !== "undefined") {
    pieces = window.location.href.split("setToken=");
  }

  if (pieces.length == 2) {
    let postSetToken = pieces[1];
    token = postSetToken?.split("&")[0];
    storeLocal("token", token);
  }
};

let setToken = (t) => {
  token = t;
  storeLocal("token", t);
};

let uploadToS3 = (file, signedUrl) => {
  return new Promise((resolve, reject) => {
    let successResponses = [200, 201];
    let xhr = createCORSRequest("PUT", signedUrl, {});
    if (!xhr) {
      return reject(Error("CORS not supported"));
    }

    xhr.onload = () => {
      if (successResponses.indexOf(xhr.status) >= 0) {
        resolve(xhr.status);
      } else {
        reject(xhr.status);
      }
    };

    xhr.onerror = reject;

    let disposition = file.type.substr(0, 6) === "image/" ? "inline" : "attachment";
    xhr.setRequestHeader("Content-Disposition", disposition + '; filename="' + file.name + '"');
    xhr.send(file);
  });
};

let createCORSRequest = (method, url, opts) => {
  opts = opts || {};
  let xhr = new XMLHttpRequest();

  if (xhr.withCredentials != null) {
    xhr.open(method, url, true);
    if (opts.withCredentials != null) {
      xhr.withCredentials = opts.withCredentials;
    }
    //@ts-ignore
  } else if (typeof XDomainRequest !== "undefined") {
    //@ts-ignore
    // eslint-disable-next-line
    xhr = new XDomainRequest();
    xhr.open(method, url);
  } else {
    xhr = null;
  }
  return xhr;
};

export { get, post, log, baseUrl, request, setToken, uploadToS3, getToken };
