import type { SVGProps } from "react";
const IconCarRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m20.05 9.43-.09-.68H21c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-1.23l-.11-.86c-.24-1.47-.52-3.14-3.7-3.14H8.04c-3.18 0-3.46 1.67-3.71 3.16l-.11.84H2.99c-.41 0-.75.34-.75.75s.34.75.75.75h1.04l-.09.68c-.49.17-.85.44-1.06.68-.5.57-.72 1.36-.61 2.23l.39 3.12c.14 1.14.54 2.58 2.08 3.08v1.45c0 .41.34.75.75.75s.75-.34.75-.75v-1.25h11.5v1.25c0 .41.34.75.75.75s.75-.34.75-.75v-1.45c1.54-.51 1.94-1.94 2.08-3.08l.39-3.12c.11-.87-.11-1.66-.61-2.23-.22-.25-.57-.51-1.06-.68zM8.04 4.75h7.92c1.91 0 1.99.47 2.22 1.86l.34 2.64H5.49l.33-2.62c.23-1.41.31-1.88 2.22-1.88m11.8 10.53c-.19 1.49-.63 1.97-1.84 1.97H6c-1.21 0-1.66-.48-1.84-1.97l-.39-3.12c-.05-.43.03-.81.25-1.05q.315-.36.99-.36H19q.675 0 .99.36c.21.24.3.61.25 1.05l-.39 3.12zm-1.81-1.81a.75.75 0 1 1-1.06 0c.28-.28.78-.28 1.06 0m-11 0a.75.75 0 1 1-1.059 1.06.75.75 0 0 1 1.059-1.06m8.22.53c0 .41-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5c.41 0 .75.34.75.75"
    />
  </svg>
);
export default IconCarRegular;
