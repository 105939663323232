import dayjs, { type Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export { dayjs, type Dayjs };

// Helper function to get the label for the date
export const getLabelForDate = (date: Dayjs, timezone: string) => {
  const today = dayjs().tz(timezone);
  const tomorrow = dayjs().tz(timezone).add(1, "day");

  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(tomorrow, "day")) {
    return "Tomorrow";
  } else {
    return date.format("ddd, MMM D");
  }
};

export function createDayJs({ timezone }: { timezone?: string }) {
  const userTimezone = dayjs.tz.guess();
  const targetTimezone = timezone || userTimezone;

  return (date?: dayjs.ConfigType) => {
    if (!date) return dayjs().tz(targetTimezone);

    // Handle UTC ISO strings (ending with Z)
    if (typeof date === "string" && date.endsWith("Z")) {
      return dayjs(date).tz(targetTimezone);
    }

    // Handle ISO strings with offset (e.g., -08:00)
    if (typeof date === "string" && /[+-]\d{2}:?\d{2}$/.test(date)) {
      return dayjs(date).tz(targetTimezone);
    }

    return dayjs.tz(date, targetTimezone);
  };
}
