import {
  App,
  Platform,
  RefreshAccessTokenDocument,
  RefreshTokenRequestType,
  Region,
  RequestLoginCodeDocument,
  ValidateLoginCodeDocument,
} from "@/generated/requests/services";
import { Service, client } from "@/lib/apollo";
import type { UserUpsert } from "generated/requests/backend";
import gql from "graphql-tag";
import * as backend from "./backend";

const RequestValidationQuery = gql`
  query RequestValidation($phone: String!) {
    requestValidation(phone: $phone)
  }
`;

export const sendCode = async (phone: string) => {
  const result = await backend.query(RequestValidationQuery, { variables: { phone } }, { throwErrors: true });

  return result?.requestValidation;
};

const FetchCurrentUserDetailsQuery = gql`
  query fetchCurrentUserDetails {
    me {
      userId
      name
      phoneNumber
      email
    }
  }
`;

export const getCurrentUser = async () => {
  try {
    const result = await backend.query(FetchCurrentUserDetailsQuery);

    return result?.me;
  } catch (error) {
    console.error("Error retrieving info.", error);
  }
};

const FetchUserDetailsQuery = gql`
  query fetchUserDetails($phone: String) {
    userForPhone(phone: $phone) {
      email
      userId
      name
      phoneNumber
    }
  }
`;

export const getUser = async (phone: string) => {
  try {
    const result = await backend.query(FetchUserDetailsQuery, { variables: { phone } });

    return result?.userForPhone;
  } catch (error) {
    console.error("Error retrieving info.", error);
  }
};

const UpdateUserMutation = gql`
  mutation updateUser($userId: ID, $phoneNumber: String, $upsert: UserUpsert) {
    privateUserDirectory {
      user(userId: $userId, phoneNumber: $phoneNumber, upsert: $upsert)
    }
  }
`;

export const updateUser = async (upsert: UserUpsert & { userId: string; phoneNumber: string }) => {
  try {
    await backend.mutate(UpdateUserMutation, { variables: { upsert } });

    return true;
  } catch (error) {
    console.error("Error updating info.", error);
  }
};

const GetChartTokenQuery = gql`
  query GetChartToken {
    chartTokenV2
  }
`;

export const getChartToken = async () => {
  try {
    const result = await backend.query(GetChartTokenQuery);

    return result?.chartTokenV2;
  } catch (error) {
    console.error("Error retrieving chart token.", error);
  }
};

export const requestLoginCode = async (smsPhoneNumber) => {
  const response = await client.query({
    query: RequestLoginCodeDocument,
    variables: {
      input: { app: App.Customer, platform: Platform.Web, type: RefreshTokenRequestType.Sms, smsPhoneNumber },
    },
    context: { service: Service.auth },
    // caching this value prevents additional requests for a login code
    fetchPolicy: "no-cache",
  });

  return response.data?.requestRefreshToken;
};

export const validateLogin = async (requestId: string, smsCode: string, region = Region.Us) => {
  const brandId = process.env.NEXT_PUBLIC_BRAND_ID;
  const response = await client.query({
    query: ValidateLoginCodeDocument,
    variables: { input: { requestId, smsCode, brandId, region } },
    context: { service: Service.auth },
  });

  return response.data?.validateRefreshTokenRequest ?? {};
};

export const refreshAccessToken = async (refreshToken: string, brandId?: string, region?: string) => {
  const response = await client.query({
    query: RefreshAccessTokenDocument,
    variables: { refreshToken, brandId, region: region as Region },
    context: { service: Service.auth },
  });

  return response.data?.requestAccessToken;
};
