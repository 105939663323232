import { datadogRum } from "@datadog/browser-rum";
import { Component, type ErrorInfo, type ReactNode } from "react";

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  readonly state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const runtimeError = new Error(error.message);
    runtimeError.name = "CrumblComRuntimeError";
    runtimeError.stack = errorInfo.componentStack as any;
    // @ts-ignore - typescript isn't finding the right tsconfig for some reason
    runtimeError.cause = error as any;

    datadogRum.addError(runtimeError);
  }

  render() {
    return this.props.children;
  }
}
