import { GetStoresCountDocument } from "@/generated/requests/services";
import { useCustomerQuery } from "@/lib/apollo-hooks";
import keyBy from "lodash/keyBy";
import reduce from "lodash/reduce";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useStores = () => {
  const { data } = useCustomerQuery(GetStoresCountDocument);
  const regions = data?.stores?.storesCountV2 || [];
  const totalStores = reduce(regions, (sum, n) => sum + n.totalStores, 0);

  return keyBy(
    [
      ...regions,
      {
        region: "world",
        totalCountries: regions?.[0]?.totalCountries,
        totalStates: null,
        totalStores,
      },
    ],
    "region",
  );
};

export function useQueryState<TState>(initialState: TState): [TState, (query: Partial<TState>) => void] {
  const router = useRouter();

  const setQueryParams = (query: Partial<TState> = {}) => {
    router.push({ pathname: router.pathname, query: { ...router.query, ...query } }, undefined, { shallow: true });
  };

  const resetQueryParams = () => {
    router.push({ pathname: router.pathname }, undefined, { shallow: true });
  };

  return [{ ...initialState, ...router.query, resetQueryParams } as TState, setQueryParams];
}
