module.exports = {
  content: ["./{components,static,pages}/**/*.{js,jsx,ts,tsx,html}"],
  theme: {
    extend: {
      fontFamily: {
        sans: ["var(--font-crumbl-sans)"],
      },
      colors: {
        // 2.0 Brand Refresh
        primary: {
          DEFAULT: "#FFB9CD", // Pink
          wcag: "#F79DB7", // Pink, but WCAG compliant for pink-on-white usage
          light: "#FFE6E5", // Pink Sugar
        },
        secondary: {
          DEFAULT: "#FCEBD9", // Butterscotch Cream
          light: "#FEF9F3", // Nilla Bean
        },
        "loyalty-silver": "#C8D9E3",
        "loyalty-silver-muted": "#D9EDF9",
        "loyalty-gold": "#FFCB65",
        "loyalty-gold-muted": "#FFE5B3",
        "error-red": "#FF4B4B",
        "dark-blue": "#086DE7",
        // Once the full rebrand is complete, we can rename this to the correct
        // "gray" and overwrite the default tailwind colors, but we don't wan't
        // to break what's there already while it's still in use.
        grey: {
          10: "rgba(0,0,0,0.1)",
          20: "rgba(0,0,0,0.2)",
          40: "rgba(0,0,0,0.4)",
          60: "rgba(0,0,0,0.6)",
        },

        // 1.0
        // IMPORTANT: please stop using these color themes
        aaa: "#AAAAAA",
        lighterGray: "#FCFCFC",
        lightGray: "#EFEFEF",
        "error-red": "#DB4156",
        pink: "#FDE5EC",
        darkPink: "#FFD3DE",
        f8: "#F8F8F8",
        70: "#707070",
      },

      maxWidth: {
        // 2.0 Brand Refresh
        "8xl": "90rem",
        "9xl": "108rem",
        // 1.0
        // IMPORTANT: please stop using anything below this line
        hero: "1920px",
      },

      // 1.0
      // IMPORTANT: please stop using anything below this line
      height: {
        modal: "87vh",
        huge: "30rem",
        "100vw": "100vw",
      },
      minHeight: {
        20: "5rem",
      },
      boxShadow: {
        topBoxShadow: "0px -3px 4px 0px rgba(0, 0, 0, 0.05)",
        orderInfoHeaderPopup: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
        "news-box": "0px 0px 15px 0px rgba(0, 0, 0, 0.10)",
        giftcard: "2px 2px 5px 2px rgba(0, 0, 0, 0.10)",
      },
      ringWidth: {
        button: "0.4rem",
      },
      animation: {
        fadeIn: "fadeIn 0.33s ease-in-out",
        shake: "shake 1s ease-in-out",
        fadeInSlow: "fadeIn 0.66s ease-in-out",
      },
      keyframes: () => ({
        fadeIn: {
          "0%": { opacity: "0%" },
          "100%": { opacity: "100%" },
        },
        shake: {
          "0%, 100%": { transform: "translateX(0)" },
          "25%": { transform: "translateX(-5px)" },
          "50%": { transform: "translateX(5px)" },
          "75%": { transform: "translateX(-5px)" },
        },
      }),
    },
  },
  plugins: [require("@tailwindcss/forms")],
};
