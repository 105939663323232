import type { SVGProps } from "react";
const IconCarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21 8.75h-1.04l.12.96c.39.15.67.37.84.57.46.51.65 1.24.55 2.03l-.39 3.12c-.14 1.13-.5 2.38-1.83 2.86V20c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-1.5H6.25V20c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-1.71c-1.33-.48-1.69-1.73-1.83-2.86l-.39-3.12c-.1-.79.09-1.52.55-2.03.17-.2.45-.42.84-.57l.12-.96H3c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.23l.11-.84c.24-1.49.52-3.16 3.7-3.16h7.92c3.18 0 3.46 1.67 3.7 3.14l.11.86H21c.41 0 .75.34.75.75s-.34.75-.75.75M7.25 14a.75.75 0 1 0-1.499 0 .75.75 0 0 0 1.499 0m8 0c0-.41-.34-.75-.75-.75h-5c-.41 0-.75.34-.75.75s.34.75.75.75h5c.41 0 .75-.34.75-.75m3 0c0-.2-.08-.39-.22-.53a.774.774 0 0 0-1.06 0 .75.75 0 1 0 1.28.53m.3-4.5-.37-2.89c-.23-1.39-.31-1.86-2.22-1.86H8.04c-1.91 0-1.99.47-2.22 1.88L5.45 9.5z"
    />
  </svg>
);
export default IconCarFilled;
