import type { DotLottieCommonPlayer } from "@dotlottie/react-player";
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";
import classNames from "classnames";
import { useRef } from "react";
import animation from "./animation.json";

type CookieLoaderProps = {
  className?: string;
};

// I used https://lottiefiles.github.io/lottie-docs/playground/json_editor/ to
// identify frames and found the frame where each cookie begins it's transition
// to the next: every 40 frames, 360 frames total, 9 possible start points, with
// a 14 frame offset for the first cookie.
const START_FRAME_OFFSET = 14;
const START_FRAME_INTERVAL = 40;
const START_POSITIONS = 9;

const getRandomStartFrame = () => {
  return START_FRAME_INTERVAL * Math.floor(Math.random() * START_POSITIONS) + START_FRAME_OFFSET;
};

export default function CookieLoader({ className }: CookieLoaderProps) {
  const lottieRef = useRef<DotLottieCommonPlayer>();

  return (
    <div className={classNames("max-w-[120px] max-h-[120px] mx-auto", className)}>
      <DotLottiePlayer
        ref={lottieRef}
        src={animation}
        autoplay
        loop
        onEvent={(event) => {
          if (event === PlayerEvents.Ready) {
            lottieRef.current?.seek(getRandomStartFrame());
          }
        }}
      />
    </div>
  );
}
