import type { SourceType } from "@/generated/requests/pos";
import { type Dayjs, getLabelForDate } from "@/lib/date";
import { Button as HeadlessButton } from "@headlessui/react";
import classNames from "classnames";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import CookieLoader from "../../atoms/CookieLoader/CookieLoader";
import Text from "../../atoms/Text/Text";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { useGetSource, useGetSourceBusinessHours, useGetStoreBySlug } from "../../operations/queries";
import { createDayJs } from "@/lib/date";

export interface MobileDateWheelPickerProps {
  timeslotsToDisplay?: string[];
  selectedDay: string;
  handleSelectDayOfTimeslots?: (dayOfTimeslots: string[]) => void;
  selectedTimeslot: string;
  setSelectedTimeslot: (timeSlot: string) => void;
  setSelectedDay: (timeSlot: string) => void;
  showMonthSelector?: boolean;
  selectedMonth?: Dayjs;
  onMonthChange?: (changeValue: number) => void;
  loading?: boolean;
  earliestAvailableTime?: string;
  staticSourceType?: SourceType;
}

export default function MobileDateWheelPicker({
  selectedDay,
  selectedTimeslot,
  setSelectedTimeslot,
  setSelectedDay,
  earliestAvailableTime,
  staticSourceType,
}: MobileDateWheelPickerProps) {
  const { orderTimeSlot, tempCateringStoreSlug } = useOrderContextNew();
  const router = useRouter();
  const orderType = router.query.type as string;
  const orderSourceType = orderType?.toUpperCase() as SourceType;
  const urlStoreSlug = router.query.slug as string;
  const hasSelectedStore: string = (router?.query?.hasSelectedStore as string) || undefined;

  const { data: storeData, loading: storeDataLoading } = useGetStoreBySlug({
    storeSlug: urlStoreSlug || tempCateringStoreSlug || hasSelectedStore,
  });
  const dayjs = createDayJs({ timezone: storeData?.timezone });
  const { data: sourceProductsData, loading: sourceProductsLoading } = useGetSource({
    storeId: storeData?.storeId,
    type: staticSourceType || orderSourceType,
    timeSlot: orderTimeSlot,
  });
  const { loading: businessHoursLoading, data } = useGetSourceBusinessHours({
    storeId: storeData?.storeId,
    type: staticSourceType || orderSourceType,
    selectedDate: dayjs(selectedDay)?.format("YYYY-MM-DD"),
  });

  const businessHoursData = data?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots;
  const containerRef = useRef(null);
  const containerRefDay = useRef(null);
  const daysRefs = useRef([]);
  const timeslotRefs = useRef([]);

  // if there is an earliest available time, like for the extra bake time modal, filter out times earlier than that time
  const allowedDaysToOrder = earliestAvailableTime
    ? sourceProductsData?.public?.sourceForStore?.allowedDaysToOrder?.filter((time) =>
        dayjs(time)?.isSameOrAfter(dayjs(earliestAvailableTime), "day"),
      )
    : sourceProductsData?.public?.sourceForStore?.allowedDaysToOrder;
  const filteredBusinessHoursData = earliestAvailableTime
    ? businessHoursData?.filter((time) => dayjs(time?.startTimestamp)?.isSameOrAfter(dayjs(earliestAvailableTime)))
    : businessHoursData;

  // scroll the selected time into view when component is rendered
  useEffect(() => {
    scrollDayIntoView("instant");
  }, []);

  useEffect(() => {
    // scroll day into view
    scrollDayIntoView("smooth");

    // scroll timestamp into view
    if (containerRef.current && !!timeslotRefs.current.length && !!filteredBusinessHoursData?.length) {
      const selectedIndex = filteredBusinessHoursData.findIndex((timeslot) =>
        dayjs(selectedTimeslot).isSame(dayjs(timeslot?.startTimestamp)),
      );
      if (selectedIndex !== -1 && timeslotRefs.current[selectedIndex]) {
        timeslotRefs.current[selectedIndex].scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [businessHoursLoading, selectedTimeslot, selectedDay]);

  const scrollDayIntoView = (scrollBehavior) => {
    if (containerRefDay.current && !!daysRefs?.current?.length && !!allowedDaysToOrder?.length) {
      const selectedDayIndex = allowedDaysToOrder?.findIndex((allowedDay) =>
        dayjs(selectedDay).isSame(dayjs(allowedDay), "date"),
      );
      if (selectedDayIndex !== -1 && daysRefs.current[selectedDayIndex]) {
        daysRefs.current[selectedDayIndex].scrollIntoView({ behavior: scrollBehavior, block: "center" }); // two 'smooth' scrolls at the same time doesn't work :/
      }
    }
  };

  return (
    <div className="w-full relative">
      <div className="w-full flex items-start justify-between gap-2">
        <div ref={containerRefDay} className="w-full max-h-[200px] overflow-y-auto">
          {sourceProductsLoading ? (
            <CookieLoader />
          ) : (
            allowedDaysToOrder?.map((dayOfTimeslot, i) => {
              const dateDisplay = getLabelForDate(dayjs(dayOfTimeslot), storeData?.timezone);
              return (
                <HeadlessButton
                  key={i}
                  className={classNames("w-full text-center cursor-pointer rounded-2.5 py-2.5 px-[15px", {
                    "bg-primary rounded-lg": dayjs(selectedDay)?.isSame(dayjs(dayOfTimeslot), "day"),
                  })}
                  ref={(el) => (daysRefs.current[i] = el)}
                  onClick={() => setSelectedDay(dayjs(dayOfTimeslot)?.format("YYYY-MM-DD"))}
                >
                  <Text>{dateDisplay}</Text>
                </HeadlessButton>
              );
            })
          )}
        </div>
        <div ref={containerRef} className="w-full max-h-[200px] overflow-y-auto">
          {businessHoursLoading || storeDataLoading ? (
            <CookieLoader />
          ) : (
            filteredBusinessHoursData?.map((timeslotObj, i) => {
              const displayTime = `${dayjs(timeslotObj?.startTimestamp)?.format("h:mm A")} - ${dayjs(timeslotObj?.endTimestamp)?.format("h:mm A")}`;
              return (
                timeslotObj?.isAvailable && (
                  <HeadlessButton
                    key={i}
                    ref={(el) => (timeslotRefs.current[i] = el)}
                    className={classNames("w-full text-center cursor-pointer rounded-2.5 py-2.5 px-[15px", {
                      "bg-primary rounded-lg": dayjs(selectedTimeslot)?.isSame(
                        dayjs(timeslotObj?.startTimestamp),
                        "minutes",
                      ),
                    })}
                    onClick={() => setSelectedTimeslot(timeslotObj?.startTimestamp)}
                  >
                    <Text>{displayTime}</Text>
                  </HeadlessButton>
                )
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
