import { localeGTM } from "@/lib/locale-helpers";
import { deleteAllCookiesAndTracking, fetchLocal, storeLocal } from "@/static/lib/util";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import UserTrackingConsentBanner from "../../organisms/UserTrackingConsentBanner/UserTrackingConsentBanner";
import UserTrackingConsentPreferences from "../../organisms/UserTrackingConsentPreferences/UserTrackingConsentPreferences";
import FocusTrap from "focus-trap-react";

const USER_TRACKING_CONSENT = "userTrackingConsent";
// Previous tracking used these values and tracked multiple values in
// localStorage. Retaining here for backwards compatibility and migrating users.
const OLD_COOKIES = {
  NECESSARY: "necessary_cookies",
  MARKETING: "marketing_cookies",
};

export interface UserTrackingConsentType {
  necessary: boolean;
  marketing: boolean;
}
export interface UserTrackingConsentContextType {
  consent?: UserTrackingConsentType;
  updateConsent: (consent: UserTrackingConsentType) => Promise<void>;
  setShowPreferences: (show: boolean) => void;
}

const UserTrackingConsentContext = createContext<UserTrackingConsentContextType>(null);

export const UserTrackingConsentContextProvider = ({ children }) => {
  const router = useRouter();
  const [showBanner, setShowBanner] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [consent, setConsent] = useState<UserTrackingConsentType>();

  const updateConsent = async (updated: UserTrackingConsentType) => {
    setConsent(updated);
    storeLocal(USER_TRACKING_CONSENT, updated);
    setShowBanner(false);
    setShowPreferences(false);

    if (updated?.marketing === false) {
      deleteAllCookiesAndTracking();
      router.push(`${router.asPath}`);
    }
  };

  useEffect(() => {
    const prevConsent = fetchLocal<UserTrackingConsentType>(USER_TRACKING_CONSENT);
    if (prevConsent) {
      setConsent(prevConsent);
      return;
    }

    const oldConsent = fetchLocal(OLD_COOKIES.NECESSARY);
    if (oldConsent) {
      updateConsent({
        necessary: true,
        marketing: fetchLocal(OLD_COOKIES.MARKETING) === "true",
      });
      storeLocal(OLD_COOKIES.NECESSARY, null);
      storeLocal(OLD_COOKIES.MARKETING, null);
      return;
    }

    setShowBanner(true);
  }, []);

  useEffect(() => {
    // Manage Google Analytics tracking
    // https://developers.google.com/analytics/devguides/collection/gajs/#disable
    const gtmId = localeGTM(router.locale);
    const key = `ga-disable-${gtmId}`;
    window[key] = consent?.marketing !== true;
  }, [consent?.marketing]);

  return (
    <UserTrackingConsentContext.Provider value={{ consent, updateConsent, setShowPreferences }}>
      <FocusTrap active={showBanner}>
        {/* Wrap multiple elements in a single parent (div or Fragment) */}
        <div>
          <UserTrackingConsentPreferences visible={showPreferences} onDismiss={() => setShowPreferences(false)} />
          <UserTrackingConsentBanner
            visible={!showPreferences && showBanner}
            onShowPreferences={() => setShowPreferences(true)}
            onDismiss={() => setShowBanner(false)}
          />
        </div>
      </FocusTrap>
      {children}
    </UserTrackingConsentContext.Provider>
  );
};

export const useUserTrackingConsentContext = () => {
  const context = useContext(UserTrackingConsentContext);

  if (!context) {
    throw new Error("useUserTrackingConsentContext must be used within UserTrackingConsentContextProvider");
  }

  return context;
};
