import { type DocumentNode, type TypedQueryDocumentNode } from "graphql";
import { client, Service } from "../../lib/apollo";
import { type GraphQLVariables } from "./apollo";

export const URL = process.env.NEXT_PUBLIC_BACKEND_API;

type QueryOptions = {
  throwErrors?: boolean;
};

/**
 * @deprecated - use `lib/apollo.ts#client` or `lib/apollo-hooks.ts` instead
 */
export const query = async (
  query: DocumentNode | TypedQueryDocumentNode,
  variables?: GraphQLVariables,
  options?: QueryOptions
) => {
  const response = await client.query({
    query,
    ...(variables?.variables && { variables: { ...variables.variables } }),
    context: { service: Service.backend }
  });

  if (options?.throwErrors && response.errors?.length > 0) {
    throw new Error(response.errors.map((e) => e.message).join("\n"));
  }

  return response.data;
};

/**
 * @deprecated - use `lib/apollo.ts#client` or `lib/apollo-hooks.ts` instead
 */
export const mutate = async (
  query: DocumentNode | TypedQueryDocumentNode,
  variables?: GraphQLVariables,
  options?: QueryOptions
) => {
  const response = await client.mutate({
    mutation: query,
    ...(variables?.variables && { variables: { ...variables.variables } }),
    context: { service: Service.backend }
  });

  if (options?.throwErrors && response.errors?.length > 0) {
    throw new Error(response.errors.map((e) => e.message).join("\n"));
  }

  return response.data;
};
