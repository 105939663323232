let sentEvents = {};

const fbEvent = (event, opts = null) => {
  if (sentEvents[event]) {
    return;
  }
  if (window.fbq) {
    sentEvents[event] = true;
    window.fbq("track", event, opts);
  }
};

const trackFBAddFlavorToCart = (price, currency, flavorIds) => {
  fbEvent("AddToCart", {
    value: price,
    currency: currency,
    content_type: "product_group",
    content_ids: flavorIds
  });
};

const trackFBAddProductToCart = (price, currency, productIds) => {
  fbEvent("AddToCart", {
    value: price,
    currency: currency,
    content_type: "product",
    content_ids: productIds
  });
};

const trackFBViewProductContent = (price, currency, productIds) => {
  fbEvent("ViewContent", {
    value: price,
    currency: currency,
    content_type: "product",
    content_ids: productIds
  });
};

const trackFBViewFlavorContent = (price, currency, flavorIds) => {
  fbEvent("ViewContent", {
    value: price,
    currency: currency,
    content_type: "product_group",
    content_ids: flavorIds
  });
};

const trackFBPurchase = (orderId, price, currency, contentIds, contentType) => {
  fbEvent("Purchase", {
    order_id: orderId,
    value: price,
    currency: currency,
    content_type: contentType,
    content_ids: contentIds
  });
};

export {
  fbEvent,
  trackFBAddFlavorToCart,
  trackFBAddProductToCart,
  trackFBViewProductContent,
  trackFBViewFlavorContent,
  trackFBPurchase
};
