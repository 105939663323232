import type { PublicStoreInfo } from "@/generated/requests/backend";
import {
  type Account,
  type AllPaymentMethodsInput,
  type CaptureOrderUpsert,
  Currency,
  type FormattedOrderReceipt,
  type GenericSourceForStoreQuery,
  type Money,
  type Order,
  type OrderError,
  type OrderFulfillmentInput,
  type OrderItemInput,
  OrderOrigin,
  type OrderRewardProduct,
  type OrderTotals,
  type OrderUpsell,
  type ProductModifierOption,
  ProductModifierSpecialSubtype,
  type Source,
  SourceBusinessHoursDocument,
  type SourceProductAutomaticDiscounts,
  SourceType,
  type Voucher,
} from "@/generated/requests/pos";
import type { Address } from "@/generated/requests/services";
import type { UpsertOrderResponse } from "@/lib/helpers";
import { trackFBAddFlavorToCart, trackFBAddProductToCart } from "@/static/lib/facebook";
import { track } from "@/static/lib/tracking";
import { centsToDecimal, formatMoney, getDiscountRate } from "@/static/lib/util";
import type { DeepPartial } from "@apollo/client/utilities";
import dayjs, { type Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { Service, client } from "lib/apollo";
import type { CustomerType } from "../CustomerContext/CustomerContext";

dayjs.extend(utc);
dayjs.extend(isBetween);

const loggerEnabled = typeof window !== "undefined" && !!window.localStorage.getItem("debugOrder");
const noop = (...args) => void 0;
/**
 * Logger controlled by localStorage flag. Must set `debugOrder` to any value to
 * see log messages.
 */
export const orderLogger = new Proxy(console, {
  get(target, name) {
    if (loggerEnabled) {
      return target[name];
    }
    return noop;
  },
});

export const SUPPORTED_ORDER_TYPES: SourceType[] = [
  SourceType.Delivery,
  SourceType.CarryOut,
  SourceType.Pickup,
  SourceType.Catering,
  SourceType.Shipping,
];

export function getSourceTypeFromUrlOrderType(urlOrderType: string) {
  const map = {
    delivery: SourceType.Delivery,
    carry_out: SourceType.CarryOut,
    pickup: SourceType.Pickup,
    catering: SourceType.Catering,
  };
  return map[urlOrderType];
}

export type OrderDetailsSource = GenericSourceForStoreQuery["public"]["sourceForStore"];
export type OrderDetailsStore = PublicStoreInfo;
export type OrderDetailsAddress = Address & { addressValidated?: boolean };

export interface ClientOrderItemProductModifierOption {
  modifierOptionId: string;
  quantity: number;
  price: number;
  image?: string;
  name?: string;
}
export interface ClientOrderItemProductModifier {
  modifierId: string;
  options: ClientOrderItemProductModifierOption[];
  specialSubtypes?: ProductModifierSpecialSubtype[];
}
export interface ClientOrderItemProduct {
  productId: string;
  quantity: number;
  modifiers: ClientOrderItemProductModifier[];
}
export interface ClientOrderItemMetaOptionAvailability {
  optionId: string;
  cookieId: string;
  startDate: string;
  endDate: string;
}
export interface ClientOrderItemMeta {
  title: string;
  category: string;
  automaticDiscounts: SourceProductAutomaticDiscounts;
  lineItems: string[];
  lineItemsInfo: LineItemsInfoType[];
  images: string[];
  featuredPartners: string[];
  flavorIds: string[];
  optionAvailability: ClientOrderItemMetaOptionAvailability[];
  productQuantityByType: { [key: string]: number };
  ts: number;
}

export interface ClientOrderItem {
  product: ClientOrderItemProduct;
  meta: ClientOrderItemMeta;
  price: number;
}

export interface LineItemsInfoType {
  quantity: number;
  name: string;
  message?: string;
  price?: string;
  calories?: string;
  image?: string;
  upcharge?: string;
}
export interface TimeSlot {
  datetime: string;
  isAsap?: boolean; // delivery only
}
export type MoneyType = Omit<Money, "__typename">;

/**
 * Order details needed to generate a `CaptureOrderUpsert` object.
 */
export interface OrderDetailsType {
  orderId?: string;
  receiptId?: string;
  source?: OrderDetailsSource;
  store?: OrderDetailsStore;
  storeSources?: DeepPartial<Source[]>;
  address?: OrderDetailsAddress;
  customerId?: string;
  shipping?: any;
  timeSlot?: TimeSlot;
  name?: string;
  note?: string;
  email?: string;
  items?: ClientOrderItem[];
  totals?: OrderTotals;
  upsell?: OrderUpsell;
  paymentMethods?: AllPaymentMethodsInput;
  crumblCashAccount?: DeepPartial<Account>;
  rewardProducts?: OrderRewardProduct[];
  vouchers?: Voucher[];
  isUpdating?: boolean;
  lastUpdateEndedAt?: Date;
  formattedReceipt?: FormattedOrderReceipt;
  recentlyRemovedItem?: ClientOrderItem;
  tipSelection?: OrderTipSelection;
  processingState?: OrderProcessingState;
  paymentIntent?: UpsertOrderResponse["paymentIntent"];
  validationErrors?: OrderError[];
}

export type OrderProcessingState = {
  type: OrderProcessingStateType;
  error?: string;
  validationErrors?: OrderError[];
};

export enum OrderProcessingStateType {
  NotProcessing = "NOT_PROCESSING",
  Finalizing = "FINALIZING",
  FailedFinalizing = "FAILED_FINALIZING",
  Finalized = "FINALIZED",
  Confirming = "CONFIRMING",
  FailedConfirming = "FAILED_CONFIRMING",
  Confirmed = "CONFIRMED",
  Capturing = "CAPTURING",
  FailedCapture = "FAILED_CAPTURE",
  Captured = "CAPTURED",
}

export type OrderTipSelection = {
  custom?: boolean;
  percent?: number;
  amount?: number;
};

const PICKUP_INTERVAL = 15;
const DELIVERY_INTERVAL = 30;
const DEFAULT_TIMEZONE = "America/Chicago";
const GIFT_WRAPPING = "GIFTWRAPPING";
const MYSTERY_COOKIE_ID = "37ab4760-7e09-11ec-b436-b90f8a5683db:Cookie";
const MYSTERY_COOKIE_IMAGE = "/images/mystery-cookie.gif";
const COOKIE_FLAVOR = "COOKIEFLAVOR";
const ICECREAM_FLAVOR = "ICECREAMFLAVOR";
const MINI_COOKIE_FLAVOR = "MINI_COOKIE_FLAVOR";
const PREFILL_COOKIE_FLAVOR = "PREFILL_COOKIE_FLAVOR";
const PREFILL_WEEKLY_MENU = "PREFILL_WEEKLY_MENU";
export const GIFTCARD = "giftcard";
export const VOUCHER = "voucher";

/**
 * Build OrderFulfillmentInput object
 *
 * @param order the current order object
 * @returns order fulfillment input
 */
const buildOrderFulfillment = (order: OrderDetailsType): OrderFulfillmentInput => {
  switch (order?.source?.type) {
    case SourceType.Delivery:
      return {
        delivery: {
          name: order.address?.name,
          addressId: order.address?.addressId,
          deliveryWindowStart: dayjs(order.timeSlot?.datetime).utc().format(),
          deliveryWindowEnd: dayjs(order.timeSlot?.datetime).utc().add(DELIVERY_INTERVAL, "minutes").format(),
          deliverAsap: order.timeSlot?.isAsap,
        },
      };
    case SourceType.CarryOut:
    case SourceType.Pickup:
      return {
        pickup: {
          name: order.name || "",
          pickupAt: dayjs(order.timeSlot?.datetime).utc().format(),
          // TODO: add `customerArrivedDescription` for curbside orders?
        },
      };
    case SourceType.Catering:
      return {
        terminal: {
          isCatering: true,
          name: order.name || "",
          email: order.email || "",
          pickupAt: dayjs(order.timeSlot?.datetime).utc().format(),
        },
      };
    case SourceType.Shipping:
      return {
        shipping: {
          name: order.address?.name,
          addressId: order.address?.addressId,
          rateId: order.shipping?.rate?.shippingRateId,
        },
      };
    default:
      return {};
  }
};

/**
 * Build OrderItemInput array
 *
 * @param order the current order object
 * @returns order item input array
 */
const buildOrderItems = (order: OrderDetailsType): OrderItemInput[] => {
  return (
    order?.items?.map(({ product }) => ({
      ...product,
      modifiers: product?.modifiers?.map((modifier) => ({
        modifierId: modifier.modifierId,
        options: modifier?.options?.map((option) => ({
          modifierOptionId: option.modifierOptionId,
          quantity: option.quantity,
        })),
      })),
    })) || []
  );
};

/**
 * Build `CaptureOrderUpsert` object
 *
 * The final upsert is a little challenging to work with while building out an
 * order object (see `buildOrderFulfillment`), so we use this function to build
 * it out using a separate order details object for tracking the information.
 *
 * @param order the current order object
 * @returns order upsert object
 */
export const buildOrderUpsert = (order: OrderDetailsType, customer: CustomerType = undefined): CaptureOrderUpsert => {
  const items = buildOrderItems(order);
  const fulfillment = buildOrderFulfillment(order);
  const vouchers = order?.totals?.vouchers?.map((voucher) => ({ voucherId: voucher.voucherId }));
  const rewardProducts = order?.rewardProducts?.map(({ rewardProductId }) => ({ rewardProductId }));
  const defaultTip = { currency: getCurrency(order), amount: 200 };
  const tip = order?.totals?.tip?.currency ? order.totals.tip : defaultTip;

  // if the crumblCashAccount is being used, make sure the amount is a valid one
  const paymentMethods = {
    ...order?.paymentMethods,
    accounts: (order?.paymentMethods?.accounts || []).map((acct) =>
      acct.accountId === order?.crumblCashAccount?.accountId
        ? {
            ...acct,
            amount: {
              ...acct.amount,
              amount: Math.min(order?.crumblCashAccount?.balance || 0, order?.totals?.total?.amount || 0),
            },
          }
        : acct,
    ),
  };

  return {
    origin: OrderOrigin.Web,
    // TODO: include a git commit hash so we can track down errors?
    // originVersion: "",
    orderId: order?.orderId,
    sourceId: order?.source?.sourceId,
    customerId: customer?.userId || order?.customerId,
    notes: order?.note,
    items,
    fulfillment,
    tip,
    vouchers,
    paymentMethods,
    rewardProducts: rewardProducts,
  };
};

/**
 * Migrate products to a new source
 *
 * Whenever we change the source, we have to migrate products because pricing
 * and availability is source-specific.
 *
 * @param source the new source for the migration
 * @param products the products previously added to the order
 * @returns products available with the new source
 */
export const migrateSourceProducts = (source: OrderDetailsSource, products: any[]): any[] => {
  if (!source) {
    return products;
  }

  const result = products
    ?.filter(({ product }) => {
      // validate product, modifiers, and options are available on the new
      // source
      const productId = product?.productId;
      const sourceProduct = source?.products?.find((p) => p?.product?.productId === productId);

      if (!sourceProduct) {
        return false;
      }

      const wrongModifiers = product?.modifiers?.find((modifier) => {
        const sourceModifier = sourceProduct?.product?.modifiers?.find((m) => m.modifierId === modifier.modifierId);

        if (!sourceModifier) {
          return true;
        }

        const sourceModifierOptionIds = sourceModifier?.options?.map(({ optionId }) => optionId);
        const wrongOption = modifier?.options?.find(
          ({ modifierOptionId }) => !sourceModifierOptionIds?.includes(modifierOptionId),
        );
        return !!wrongOption;
      });

      return !wrongModifiers;
    })
    ?.map((p) => {
      // update pricing since prices are source-specific
      const { product, price } = p;
      const productId = product?.productId;
      const sourceProduct = source.products?.find((p) => p?.product?.productId === productId);
      return { ...p, price: sourceProduct?.price || price };
    });
  return result;
};

/**
 * Generate available time slots for a given source and store
 *
 * @param source the source object for the order
 * @param store the store object for the order
 * @param availableCateringDates if the source type is Catering, pass in the available catering dates as well so this function can generate the timeslots in the appropriate format for those dates
 * @returns time slots
 */
export const generateTimeSlots = (
  source,
  store,
  availableCateringDates?: CateringDayInfoForTimeslotGeneration[],
): string[][] => {
  if (!source || (source.type === SourceType.Catering && !availableCateringDates)) {
    return [];
  }

  if (source.type === SourceType.Catering) {
    return availableCateringDates.map((cateringDay) => {
      const timesForDay = getAvailableTimesForCateringDay(cateringDay, source);
      return timesForDay.map((date) => dayjs(date).format());
    });
  }

  const isDelivery = source.type === SourceType.Delivery;
  const interval = isDelivery ? DELIVERY_INTERVAL : PICKUP_INTERVAL;
  const timezone = source.businessHours?.timezone || store?.storeHours?.timezone || store?.timezone || DEFAULT_TIMEZONE;
  const now = dayjs().tz(timezone);
  const dateFormat = "YYYY-MM-DD";

  return source?.businessHours?.openings
    ?.map(({ open, close }) => {
      let [start, end] = [open, close].map((time) => dayjs(time).tz(timezone));

      // delivery window closes 30 minutes before the store closes
      if (isDelivery) {
        end = end.subtract(30, "minutes");
      }

      const times = [];
      let pointer = start.clone();

      while (pointer.isBefore(end)) {
        // only allow times in the future (+3 minutes)
        if (pointer.diff(now, "minutes") > 3) {
          times.push(pointer.clone().format());
        }

        pointer = pointer.add(interval, "minutes");
      }

      const closures = source?.businessHours?.sourceClosures
        ?.filter(
          ({ eventDate }) => start.format(dateFormat) == dayjs(eventDate, dateFormat).tz(timezone).format(dateFormat),
        )
        ?.map(({ hours }) => [
          dayjs(`${hours.startingDate} ${hours.startingHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
          dayjs(`${hours.endingDate} ${hours.endHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
        ]);
      if (!closures?.length) {
        return times;
      }
      return times.filter((time) => {
        return !closures?.find(([start, end]) => dayjs(time).isBetween(start, end, undefined, "[)"));
      });
    })
    ?.filter((times) => times.length);
};

/**
 * Find the next available time slot for the given order
 *
 * @param source the source object for the order
 * @param store the store object for the order
 * @param address (optional) the address object for the order
 * @returns a pre-selected time slot, if available
 */
export const preSelectWhen = async (source, store, address): Promise<TimeSlot> => {
  const type = source.type;
  const storeId = store?.storeId;
  if (type == SourceType.Catering || !storeId) {
    return null;
  }

  const asap = await getAsap(source, store, address);
  if (asap) {
    return { datetime: asap, isAsap: true };
  }

  const availableDates = generateTimeSlots(source, store);
  const datetime = availableDates?.[0]?.[0];

  if (!datetime) {
    return;
  }

  return { datetime, isAsap: false };
};

/**
 * Find the next available time slot available that is ASAP (delivery only)
 *
 * @param source the source object for the order
 * @param store the store object for the order
 * @param address the address object for the order
 * @returns an ASAP delivery time slot, if available
 */
export const getAsap = async (source, store, address): Promise<string> => {
  if (source.type !== SourceType.Delivery) {
    return null;
  }

  const storeId = store?.storeId;
  const addressId = address?.addressId;

  if (!storeId || !addressId) {
    return null;
  }

  const { data } = await client.query({
    query: SourceBusinessHoursDocument,
    context: { service: Service.pos },
    variables: {
      storeId,
      type: source?.type,
      selectedDate: dayjs().format("YYYY-MM-DD"),
    },
  });
  const timeSlots = data?.public?.sourceForStore?.businessHoursForDay?.pickupTimeSlots;
  const result = timeSlots?.find((slot) => slot.isAvailable)?.startTimestamp;
  return result;
};

/**
 * Get the timezone for the given order
 *
 * @param order the current order object
 * @returns  a dayjs timezone string
 */
export const getOrderTimezone = (order: Order) => {
  return order?.source?.businessHours?.timezone || DEFAULT_TIMEZONE;
};

/**
 * Get the currency for the given order
 *
 * @param order the current order object
 * @returns a currency code
 */
export const getCurrency = (order: OrderDetailsType): Currency => {
  return order?.totals?.total?.currency || order?.store?.currency || Currency.Usd;
};

/**
 * Check the timeSlot for the given order. Returns a new, updated timeSlot for
 * the order or nothing at all if no change is required.
 *
 * @param order the current order object
 * @returns a new, updated timeSlot for the order
 */
export const checkTimeSlot = async (order: OrderDetailsType): Promise<TimeSlot> => {
  if (!order?.source || !order?.store) {
    return;
  }

  const timeSlot = await preSelectWhen(order.source, order.store, order.address);
  if (!order.timeSlot?.datetime || dayjs(order.timeSlot?.datetime).isBefore(dayjs(timeSlot?.datetime))) {
    return timeSlot;
  }
};

//Gift Wrapping Helpers
const getSourceProductFromItem = (order: OrderDetailsType, itemToUpdate: ClientOrderItem) =>
  order?.source?.products?.find((p) => p.product?.productId === itemToUpdate.product.productId)?.product;
export const getProductGiftWrapModifierWithAllOptions = (sourceProduct) =>
  sourceProduct?.modifiers?.find((modifier) =>
    modifier.specialSubtypes.includes(ProductModifierSpecialSubtype.Giftwrapping),
  );
const getModifierListWithRemovedGiftWrapModifier = (order: OrderDetailsType, itemToUpdate: ClientOrderItem) => {
  const sourceProduct = getSourceProductFromItem(order, itemToUpdate);
  const productGiftWrapModifierWithAllOptions = getProductGiftWrapModifierWithAllOptions(sourceProduct);
  return itemToUpdate?.product?.modifiers?.filter(
    (modifier) => modifier.modifierId !== productGiftWrapModifierWithAllOptions?.modifierId,
  );
};

const getModifierListWithUpdatedGiftWrapModifier = (
  order: OrderDetailsType,
  itemToUpdate: ClientOrderItem,
  newModifierOption: ProductModifierOption,
): ClientOrderItemProductModifier[] => {
  const sourceProduct = getSourceProductFromItem(order, itemToUpdate);
  const productGiftWrapModifierWithAllOptions = getProductGiftWrapModifierWithAllOptions(sourceProduct);
  const updatedModifierList = itemToUpdate?.product?.modifiers?.map((modifier) =>
    modifier.modifierId === productGiftWrapModifierWithAllOptions?.modifierId
      ? {
          ...modifier,
          options: [
            {
              modifierOptionId: newModifierOption.optionId,
              quantity: 1,
              price: newModifierOption.price,
              name: newModifierOption.name,
            },
          ],
        }
      : modifier,
  );
  return updatedModifierList;
};

export const getItemWithUpdatedGiftWrapModifierAndLineItems = (
  order: OrderDetailsType,
  itemToUpdate: ClientOrderItem,
  newModifierOption: ProductModifierOption,
): ClientOrderItem => {
  const newModifierListWithUpdatedGiftWrapModifier = getModifierListWithUpdatedGiftWrapModifier(
    order,
    itemToUpdate,
    newModifierOption,
  );
  const newLineItemsInfo = itemToUpdate.meta.lineItemsInfo.map((info) =>
    info.name === "Gift Wrapping" ? { ...info, message: newModifierOption.name } : info,
  );
  return {
    ...itemToUpdate,
    product: {
      ...itemToUpdate.product,
      modifiers: newModifierListWithUpdatedGiftWrapModifier,
    },
    meta: {
      ...itemToUpdate.meta,
      lineItemsInfo: newLineItemsInfo,
    },
  };
};

export const getItemWithRemovedGiftWrapModifierAndLineItems = (
  order: OrderDetailsType,
  itemToUpdate: ClientOrderItem,
): ClientOrderItem => {
  const newModifierListWithoutGiftWrapModifier = getModifierListWithRemovedGiftWrapModifier(order, itemToUpdate);
  return {
    ...itemToUpdate,
    product: {
      ...itemToUpdate.product,
      modifiers: newModifierListWithoutGiftWrapModifier,
    },
    meta: {
      ...itemToUpdate.meta,
      lineItemsInfo: itemToUpdate.meta.lineItemsInfo.filter((info) => info.name !== "Gift Wrapping"),
      lineItems: itemToUpdate.meta.lineItems.filter((lineItem) => !lineItem.includes("Gift Wrapping")),
    },
  };
};

export const getProductGiftWrapModifierOptionWithAllDataForItem = (sourceProduct, item: ClientOrderItem) => {
  const productGiftWrapModifierWithAllOptions = getProductGiftWrapModifierWithAllOptions(sourceProduct);
  const itemGiftWrapModifier = item?.product?.modifiers?.find(
    (modifier) => modifier.modifierId == productGiftWrapModifierWithAllOptions?.modifierId,
  );
  return productGiftWrapModifierWithAllOptions.options.find(
    (option) => option.optionId == itemGiftWrapModifier?.options[0].modifierOptionId,
  );
};

export const getAvailableTimesForCateringDay = (
  { startDate, endDate }: CateringDayInfoForTimeslotGeneration,
  source,
) => {
  const closures = source?.businessHours?.sourceClosures || [];
  const timezone = source?.businessHours?.timezone || "America/Boise";
  const dateFormat = "YYYY-MM-DD";

  const start = dayjs(startDate).tz(timezone);
  const end = dayjs(endDate).tz(timezone);
  const times = [];
  let pointer = start.clone();
  while (pointer.isSameOrBefore(end)) {
    times.push(pointer.clone());

    pointer = pointer.add(30, "minutes");
  }

  const dayOpenings = closures
    ?.filter(({ eventDate }) => start.format(dateFormat) == dayjs(eventDate, dateFormat).format(dateFormat))
    ?.map(({ hours }) => [
      dayjs(`${hours.startingDate} ${hours.startingHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
      dayjs(`${hours.endingDate} ${hours.endHour}:00:00`, "YYYY-MM-DD HH:mm:ss").tz(timezone),
    ]);

  if (!dayOpenings?.length) {
    return times;
  }

  return times.filter((time) => !dayOpenings?.find(([start, end]) => dayjs(time).isBetween(start, end)));
};

type CateringDayInfoForTimeslotGeneration = {
  startDate: string | Dayjs;
  endDate: string | Dayjs;
};
