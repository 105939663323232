import { fetchLocal } from "../static/lib/util";
import { dayjs } from "./date";

export type UserTokenPayload = {
  userId: string;
  app: string;
  platform: string;
  brandId?: string;
  region?: string;
  masqueraderId?: string;
  exp: number;
  iat: number;
};

export const hasValidToken = () => {
  return !isTokenExpired(getUserToken());
};

export const getUserToken = () => fetchLocal<string>("token");

export const decodeUserToken = (token: string): UserTokenPayload | null => {
  if (!token) return null;

  const [, payload] = token.split(".");
  const decodedPayload = JSON.parse(atob(payload));
  return decodedPayload;
};

export const isTokenExpired = (token: string | null): boolean => {
  if (!token) {
    return true;
  }

  const payload = decodeUserToken(token);

  if (!payload) return true;

  return dayjs.unix(payload.exp).isBefore(dayjs());
};
