import type { SVGProps } from "react";
const IconNoteListCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 81 80" {...props}>
    <path
      fill="currentColor"
      d="M57.168 8.332H23.835c-7.634 0-11.667 4.033-11.667 11.667v40c0 7.633 4.033 11.666 11.667 11.666h33.333c7.633 0 11.667-4.033 11.667-11.666v-40c0-7.634-4.034-11.667-11.667-11.667m8.333 51.667c0 5.763-2.57 8.333-8.333 8.333H23.835c-5.764 0-8.334-2.57-8.334-8.333v-40c0-5.764 2.57-8.334 8.334-8.334h33.333c5.763 0 8.333 2.57 8.333 8.334zm-8.333-33.334c0 .92-.747 1.667-1.667 1.667H42.168a1.667 1.667 0 0 1 0-3.333h13.333c.92 0 1.667.746 1.667 1.666m0 13.334c0 .92-.747 1.666-1.667 1.666H42.168a1.667 1.667 0 0 1 0-3.333h13.333c.92 0 1.667.747 1.667 1.667m-23.82-16.734c.65.65.65 1.707 0 2.357l-4.443 4.443a1.674 1.674 0 0 1-2.36 0l-2.224-2.223a1.67 1.67 0 0 1 0-2.357c.65-.65 1.707-.65 2.357 0l1.043 1.044 3.264-3.264c.656-.65 1.71-.65 2.363 0m0 13.334c.65.65.65 1.706 0 2.356L28.905 43.4a1.674 1.674 0 0 1-2.36 0l-2.224-2.224a1.67 1.67 0 0 1 0-2.356c.65-.65 1.707-.65 2.357 0l1.043 1.043 3.264-3.263c.656-.65 1.71-.65 2.363 0m23.82 16.733c0 .92-.747 1.667-1.667 1.667H42.168a1.667 1.667 0 0 1 0-3.334h13.333c.92 0 1.667.747 1.667 1.667m-23.82-3.4c.65.65.65 1.707 0 2.357l-4.443 4.443a1.674 1.674 0 0 1-2.36 0L24.32 54.51a1.67 1.67 0 0 1 0-2.357c.65-.65 1.707-.65 2.357 0l1.043 1.043 3.264-3.263c.656-.65 1.71-.65 2.363 0"
    />
  </svg>
);
export default IconNoteListCheck;
