import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import Text from "../../atoms/Text/Text";
import Toggle from "../../atoms/Toggle/Toggle";
import { useUserTrackingConsentContext } from "../../contexts/UserTrackingConsentContext/UserTrackingConsentContext";
import Button from "../../molecules/Button/Button";
import Modal from "../../molecules/Modal/Modal";

export interface UserTrackingConsentPreferencesProps {
  visible?: boolean;
  onDismiss?: Function;
}

export default function UserTrackingConsentPreferences({
  visible = false,
  onDismiss
}: UserTrackingConsentPreferencesProps) {
  const { t } = useTranslation();
  const { consent, updateConsent } = useUserTrackingConsentContext();
  const [marketing, setMarketing] = useState<boolean>(false);

  const handleDismiss = () => {
    onDismiss?.();
  };

  const handleUpdatePreferences = () => {
    updateConsent({
      necessary: true,
      marketing
    });
    handleDismiss();
  };

  // keep toggle in sync with consent – whenever we re-show the modal, we need
  // to update the toggle.
  useEffect(() => {
    if (visible) {
      setMarketing(!!consent?.marketing);
    }
  }, [visible, consent?.marketing]);

  return (
    <Modal variant="secondary" isOpen={visible} onClose={handleDismiss} title={t("cookie_settings")}>
      <div className="flex flex-col gap-y-10">
        <div className="flex items-start justify-between text-base gap-5">
          <div className="flex flex-col gap-y-[10px]">
            <Text variant="title3">{t("necessary_cookies_always_on")}</Text>
            <Text>{t("necessary_cookies_text")}</Text>
          </div>
          <div className="flex-shrink-0 self-center">
            <Toggle checked={true} disabled />
          </div>
        </div>
        <div className="flex items-start justify-between text-base gap-5">
          <div className="flex flex-col gap-y-[10px]">
            <Text variant="title3">{t("marketing_cookies")}</Text>
            <Text>{t("marketing_cookies_text")}</Text>
          </div>
          <div className="flex-shrink-0 self-center">
            <Toggle checked={marketing} onChange={setMarketing} description={t("")} />
          </div>
        </div>
        <Button className="w-full sm:w-fit" onClick={handleUpdatePreferences}>
          {t("update_preferences")}
        </Button>
      </div>
    </Modal>
  );
}
