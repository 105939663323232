import { createContext, useContext, useEffect, useState } from "react";

export const AppContext = createContext<AppContextType>(null);

/**
 * @deprecated this is a legacy context, do not use with components/v2
 */
export const AppContextProvider = ({ children }) => {
  const [isNewMobileView, setIsNewMobileView] = useState(false);
  const [showHeaderButtons, setShowHeaderButtons] = useState(true);
  const [headerDismissAction, setHeaderDismissAction] = useState(null);

  const isClient = typeof window !== "undefined";

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const listener = () => {
      setIsNewMobileView(window.innerWidth <= 640);
    };

    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [isClient]);

  return (
    <AppContext.Provider
      value={{
        isNewMobileView,
        showHeaderButtons,
        setShowHeaderButtons,
        headerDismissAction,
        setHeaderDismissAction,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};

export type AppContextType = {
  isNewMobileView: boolean;
  showHeaderButtons: boolean;
  setShowHeaderButtons: Function;
  headerDismissAction?: Function;
  setHeaderDismissAction: Function;
};
