import { fetchLocal, storeLocal } from "@/static/lib/util";
import { LngLatLike } from "mapbox-gl";
import { createContext, useContext, useState } from "react";

export type GeolocationContextType = {
  geolocation?: LngLatLike;
  loading: boolean;
  fetchGeolocation: () => void;
  error?: string | null;
};

const GeolocationContext = createContext<GeolocationContextType | null>(null);

export const GeolocationContextProvider = ({ children }) => {
  const [geolocation, setGeolocation] = useState<LngLatLike | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchGeolocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          const geolocation = { lat: latitude, lng: longitude };
          setGeolocation(geolocation);
          storeLocal("userLocation", location);
          setError(null);
          setLoading(false);
        },
        () => {
          console.warn("Error fetching geolocation");
          const lastKnownLocation = fetchLocal("userLocation");
          if (lastKnownLocation && lastKnownLocation.lat && lastKnownLocation.lng) {
            setGeolocation(lastKnownLocation);
            setError(null);
          } else {
            // Set to "error" if no location can be obtained or found in localStorage
            console.warn("Geolocation cannot be set.");
            setError("Error setting geolocation.");
          }
          setLoading(false);
        }
      );
    } else {
      // Browser does not support Geolocation
      console.error("Geolocation is not supported by this browser.");
      setError("Error setting geolocation.");
    }
  };

  return (
    <GeolocationContext.Provider value={{ geolocation, loading, fetchGeolocation, error }}>
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocation = () => {
  const context = useContext(GeolocationContext);

  if (!context) {
    throw new Error("useGeolocation must be used within a GeolocationContextProvider");
  }
  return context;
};
