import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";

export interface ToggleProps {
  /**
   * Determine whether the toggle is on or off
   */
  checked?: boolean;
  /**
   * Disable the toggle (read-only)
   */
  disabled?: boolean;
  /**
   * Accessibility text describing the toggle's (screen readers only)
   */
  description?: string;
  /**
   * onChange callback invoked when the toggle changes
   */
  onChange?: (checked: boolean) => void;
  /**
   * className string to apply to the toggle component
   */
  className?: string;
}

export default function Toggle({ checked, disabled = false, description, onChange, className }: ToggleProps) {
  const { t } = useTranslation();

  const handleChange = (checked: boolean) => {
    if (disabled) {
      return;
    }

    onChange?.(checked);
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      className={classNames(
        "relative inline-flex h-7 w-12 shrink-0 cursor-pointer items-center",
        "rounded-full border-2 border-transparent",
        "transition-colors duration-200 ease-in-out",
        checked ? "bg-primary" : "bg-grey-20",
        disabled && "opacity-25",
        className
      )}
    >
      <span className="sr-only">{description || t("toggle")}</span>
      <span
        aria-hidden="true"
        className={classNames(
          "pointer-events-none inline-block size-6 transform",
          "rounded-full bg-white shadow-lg ring-0",
          "transition duration-200 ease-in-out",
          checked ? "translate-x-5" : "translate-x-0"
        )}
      />
    </Switch>
  );
}
