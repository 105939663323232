import type { SVGProps } from "react";
const IconStoreFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20 13.08V18q0 3-3 3h-2.67a.3.3 0 0 1-.3-.3v-3.078c0-.997-.675-1.927-1.658-2.093A2.005 2.005 0 0 0 10.03 17.5v3.2a.3.3 0 0 1-.3.3H7q-3 0-3-3v-4.92c.59.28 1.24.42 1.9.42 1.14 0 2.23-.4 3.07-1.06.84.66 1.92 1.06 3.03 1.06 1.12 0 2.2-.4 3.04-1.06.84.66 1.93 1.06 3.07 1.06.65 0 1.3-.14 1.89-.42M7.5 3C5 3 4.617 3.82 4.205 5.038l-1.04 3.073c-.438 1.294.005 2.791 1.199 3.481.449.26.974.408 1.535.408 1.33 0 2.639-.83 3.055-2 .406 1.17 1.715 2 3.045 2s2.639-.83 3.045-2c.416 1.17 1.726 2 3.055 2 .562 0 1.086-.148 1.535-.408 1.194-.69 1.637-2.187 1.199-3.481l-1.04-3.073C19.383 3.82 19 3 16.5 3z"
    />
  </svg>
);
export default IconStoreFilled;
