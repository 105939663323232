import { Portal } from "@headlessui/react";
import { useAppContext } from "components/OldAppContext";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

//top-[48px] h-[calc(100vh_-_48px)]  max-h-[calc(100vh_-_48px)]
const HEADER_HEIGHT = "48px";

export default function FullScreenMobileModal({ children, visible = false }) {
  return (
    <Portal>
      <AnimatePresence mode="wait" initial={visible}>
        {visible && (
          <ModalMotion
            isVertical={true}
            className={`fixed z-[2] top-[${HEADER_HEIGHT}] left-0 right-0 bottom-0 overflow-y-auto overflow-x-hidden bg-white`}
          >
            <div className="fullscreen-mobile-modal-container h-[calc(100vh_-_48px)] flex justify-center">
              <div className="fullscreen-mobile-modal-contents w-full max-h-[calc(100vh_-_48px)] overflow-y-auto">
                {children}
              </div>
            </div>
          </ModalMotion>
        )}
      </AnimatePresence>
    </Portal>
  );
}

function ModalMotion({ children, isVertical = false, reverse = false, className = "", contentKey = "" }) {
  const { isNewMobileView } = useAppContext();
  if (!isNewMobileView) {
    return children;
  }

  const props = isVertical
    ? {
        initial: { y: 50, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: 50, opacity: 0 },
      }
    : {
        initial: { x: reverse ? -50 : 50, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        //exit: { x: reverse ? 50 : -50, opacity: 0 }
      };

  return (
    <motion.div {...props} className={className} key={contentKey} transition={{ type: "tween", duration: 0.15 }}>
      {children}
    </motion.div>
  );
}
