import type { SVGProps } from "react";
const IconAlarmPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17.47 3.53a.75.75 0 0 1 1.061-1.061l2 2a.75.75 0 0 1-1.06 1.061zm-12.94 0a.75.75 0 1 0-1.061-1.061l-2 2a.75.75 0 0 0 1.06 1.061zm6.5 15.72a7.1 7.1 0 0 1-3.627-.98A7.24 7.24 0 0 1 3.78 12c0-3.998 3.252-7.25 7.25-7.25a7.24 7.24 0 0 1 6.273 3.631c.485.823.796 1.737.922 2.715a.75.75 0 1 0 1.488-.193A8.6 8.6 0 0 0 18.6 7.626a8.74 8.74 0 0 0-7.57-4.377c-4.825 0-8.75 3.925-8.75 8.75a8.74 8.74 0 0 0 3.616 7.064l-1.422 1.402a.75.75 0 0 0 1.052 1.069l1.702-1.678a8.64 8.64 0 0 0 3.802.894.75.75 0 0 0 0-1.5m-.78-11.21v4a.75.75 0 0 0 1.5 0v-4a.75.75 0 0 0-1.5 0M22.75 17v2c0 2.418-1.332 3.75-3.75 3.75h-2c-2.418 0-3.75-1.332-3.75-3.75v-2c0-2.418 1.332-3.75 3.75-3.75h2c2.418 0 3.75 1.332 3.75 3.75m-1.5 0c0-1.577-.673-2.25-2.25-2.25h-2c-1.577 0-2.25.673-2.25 2.25v2c0 1.577.673 2.25 2.25 2.25h2c1.577 0 2.25-.673 2.25-2.25zm-2 .25h-.5v-.5a.75.75 0 0 0-1.5 0v.5h-.5a.75.75 0 0 0 0 1.5h.5v.5a.75.75 0 0 0 1.5 0v-.5h.5a.75.75 0 0 0 0-1.5"
    />
  </svg>
);
export default IconAlarmPlus;
