import { parsePhoneNumber } from "awesome-phonenumber";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Region } from "generated/requests/services";
import { isNil } from "lodash";
import safeLocalStorage from "./localstorage";
import { Currency } from "./types/Currency";

dayjs.extend(utc);
dayjs.extend(timezone);

export const AppStoreURL = "https://apps.apple.com/us/app/crumbl-cookies/id1438166219?ls=1&mt=8";
export const PlayStoreUrl = "https://play.google.com/store/apps/details?id=crumbl.cookies";

export const preventRedirectBecauseOfPath = () => {
  const excludedAppStoreRedirectUrlPrefixes = ["tmobile-tuesday-oct-2024"];
  if (typeof window === "undefined") {
    return false;
  }
  return excludedAppStoreRedirectUrlPrefixes.some((prefix) => window.location.pathname.includes(prefix));
};

const isMTC = (address) => {
  return address?.id === "72bac235f04685c108534423803b1651285dba9e";
};

const openAppStorePage = () => {
  if (preventRedirectBecauseOfPath()) {
    return false;
  }
  if (typeof window !== "undefined") {
    window.location.href = AppStoreURL;
  }
};

const openPlayStorePage = () => {
  if (preventRedirectBecauseOfPath()) {
    return false;
  }
  if (typeof window !== "undefined") {
    window.location.href = PlayStoreUrl;
  }
};

export const isChromeOnIOS = () => {
  return navigator.userAgent.match("CriOS");
};

export const attemptToOpenCrumblApp = () => {
  if (isIOS()) {
    openAppStorePage();
  } else if (isAndroid()) {
    window.location.href =
      "intent://order/#Intent;package=crumbl.cookies;scheme=crumbl.cookies;action=android.intent.action.VIEW;S.browser_fallback_url=https://play.google.com/store/apps/details?id=crumbl.cookies;end";
  }
};

export const openAppOrStoreIfNeeded = () => {
  if (!isMobile()) {
    return;
  }
  if (typeof window == "undefined") {
    return;
  }
  if (isIOS()) {
    if (isChromeOnIOS()) {
      onceEveryMinutes(20, "chrome_ios") && openAppStorePage();
    }
  } else if (isAndroid()) {
    window.location.href =
      "intent://order/#Intent;package=crumbl.cookies;scheme=crumbl.cookies;action=android.intent.action.VIEW;S.browser_fallback_url=https://play.google.com/store/apps/details?id=crumbl.cookies;end";
  }
};

export const onceEveryMinutes = (minutes: number, key: string) => {
  const storageKey = `every_minutes_${key}1`;
  const marker = +localStorage.getItem(storageKey);
  if (marker && Date.now() - marker < minutes * 60 * 1000) {
    return false;
  }
  localStorage.setItem(storageKey, Date.now().toString());
  return true;
};

export const formatPhone = (number: string, country = "US"): string => {
  if (!number) return "";
  const pn = parsePhoneNumber(number, { regionCode: country });
  return pn.number.national ?? number;
};

const dispatchLocalEvent = (key, newValue) => {
  if (["order", "customer"].includes(key) && typeof window !== "undefined") {
    const newEvent = new StorageEvent("storage", { key, newValue });
    window.dispatchEvent(newEvent);
  }
};

const storeLocal = (key, newValue) => {
  dispatchLocalEvent(key, newValue);
  if (newValue) {
    safeLocalStorage.set(key, JSON.stringify(newValue));
  } else {
    safeLocalStorage.remove(key);
  }
};

const fetchLocal = <T = any>(key: string): T | null => {
  const item = safeLocalStorage.get(key);
  return item ? (JSON.parse(item) as T) : null;
};

export const asPercentage = (num: number) => {
  return `${(num * 100).toFixed(2)}%`;
};

export function formatMoney(cents: number, locale?: string, currency?: Currency) {
  if (Number.isNaN(cents) || isNil(cents)) {
    return "";
  }

  const currencyFormatter = new Intl.NumberFormat(locale || "en-US", {
    style: "currency",
    currency: currency || locale ? getCurrencyFromLocale(locale) : orderCurrency(),
  });

  const decimals = decimalPlacesForCurrency(currency);
  const valueForFormatting = cents * 10 ** -decimals;
  return currencyFormatter.format(valueForFormatting);
}

export function getDiscountRate(discounts): number {
  if (!Array.isArray(discounts) || discounts.length === 0) {
    return 0;
  }

  return discounts.reduce((acc, curr) => acc + curr.rate, 0);
}

function decimalPlacesForCurrency(currency: Currency): number {
  // TODO: Add in 1 and 3 decimal places when we expand into those countries. Also more 0 when we get there.
  switch (currency) {
    case Currency.Jpy:
    case Currency.Krw:
      return 0;
    default:
      return 2;
  }
}

export function orderCurrency() {
  const order = fetchLocal("order");
  return currencyFromOrder(order);
}

export function currencyFromOrder(order) {
  return order?.totals?.total?.currency || order?.where?.store?.currency || Currency.Usd;
}

const centsToDecimal = (amount: number, currency: Currency = Currency.Usd) => {
  if (!amount) {
    amount = 0;
  }
  const decimals = decimalPlacesForCurrency(currency || Currency.Usd);
  return (amount * 10 ** -decimals).toFixed(decimals);
};

const formatError = (code) => {
  if (code == "value_too_low") {
    return "The minimum order is $1.00";
  }

  return null;
};

const ensureOk = (res) => {
  if (!res.ok) {
    throw res.statusText;
  }
  return res;
};

const dedupeObjects = (objs) => {
  const hash = {};
  objs.forEach((o) => {
    hash[JSON.stringify(o)] = true;
  });

  //@ts-ignore
  return Object.keys(hash).map(JSON.parse);
};

const pad = (array, pad) => {
  for (let i = array.length; i < pad; i++) {
    array.push(null);
  }
  return array;
};

const noScroll = (flag) => {
  document.getElementsByTagName("body")[0].className = flag ? "noscroll" : "";
};

const bind = (self, funcs) => {
  funcs.forEach((f) => (self[f] = self[f].bind(self)));
};

const filterObject = (obj, func) => {
  const res = {};
  Object.keys(obj).forEach((k) => {
    if (func(k, obj[k])) {
      res[k] = obj[k];
    }
  });

  return res;
};

const sum = (ts: number[]) => {
  if (!ts || ts.length == 0) {
    return 0;
  }
  return Number(ts.reduce((total, t) => total + t));
};

const flatMap = (arr: any[], func = (a) => a) => {
  return arr.flatMap(func);
};

const isIOS = () => {
  if (typeof navigator === "undefined") {
    return false;
  }
  return navigator.userAgent.match("iPad") || navigator.userAgent.match("iPhone") || navigator.userAgent.match("iPod");
};

const ANDROID_RE = /Android/i;

const isAndroid = () => {
  return typeof navigator === "undefined" ? false : ANDROID_RE.test(navigator.userAgent);
};

const isMobile = () => isIOS() || isAndroid();

const groupBy = (array, key) => {
  const newObject = {};

  if (typeof array == "object") {
    array = objectValues(array);
  }

  array.forEach((value) => {
    const keyValue = value[key];
    if (!keyValue) {
      return;
    }

    if (!newObject[keyValue]) {
      newObject[keyValue] = [];
    }
    newObject[keyValue].push(value);
  });

  return newObject;
};

const objectValues = (object) => {
  return Object.values(object);
};

const sortStoreNames = (a, b): number => {
  return (a?.name || "").localeCompare(b?.name || "");
};

const currentCookieWeek = () => {
  return dayjs().tz("America/Boise").add(-18, "hours").startOf("week").utc();
};

const scrollToTop = (top = 10) => {
  window.scroll({
    top,
    behavior: "smooth",
  });
};

const round = (value: number, step = 1, fn: (v: number) => number = Math.round) => {
  return fn(value / step) * step;
};

export const formatMaybePhone = (maybePhoneNumber: string, country = "US"): string => {
  return formatPhone(maybePhoneNumber, country) || maybePhoneNumber;
};

export const isEnterKey = (key: string) => key === "Enter";

const domainRegionMap: Record<string, Region> = {
  com: Region.Us,
  ca: Region.Ca,
  "co.uk": Region.Gb,
};

export const regionDomainMap: Record<Region, string> = Object.keys(domainRegionMap)
  .map((key) => key)
  .reduce(
    (acc, curr) => {
      acc[domainRegionMap[curr]] = curr;
      return acc;
    },
    {} as Record<Region, string>,
  );

export const determineRegionFromDomain = (domain: string): Region => {
  // when running locally
  if (/localhost/.test(domain)) {
    const pathname = window.location.pathname;
    if (/en-CA/i.test(pathname)) {
      return Region.Ca;
    }
    if (/en-GB/i.test(pathname)) {
      return Region.Gb;
    }

    return Region.Us;
  }

  // deployed envs
  const domainParts = domain.split(".");
  return domainRegionMap[domainParts[domainParts.length - 1]] || Region.Us;
};

export const parseLocale = (locale: string) => {
  const [language, country = "US"] = locale?.split(/[-_]/) || [];
  return { language, country };
};

const parseRegionFromLocale = (locale: string): Region => {
  if (!locale) {
    return Region.Us;
  }

  const { country = "US" } = parseLocale(locale);

  return country as Region;
};

export const determineRegion = (locale: string): Region => {
  if (locale) {
    return parseRegionFromLocale(locale);
  }
  if (typeof window !== "undefined") {
    return determineRegionFromDomain(window.location.hostname);
  }
  return Region.Us;
};

export function getStripePublishableKeyForLocale(locale: string) {
  const region = determineRegion(locale);
  const regionToEnvVarMap = {
    US: process.env.NEXT_PUBLIC_STRIPE_KEY_US,
    CA: process.env.NEXT_PUBLIC_STRIPE_KEY_CA,
  };
  const envVar = regionToEnvVarMap[region];
  return envVar;
}

export function getStripeRegionForLocale(locale: string) {
  const region = determineRegion(locale);
  const regionToStripeRegionMap = {
    US: "US",
    CA: "CA",
  };
  return regionToStripeRegionMap[region];
}

const regionCurrencyMap: Partial<Record<Region, Currency>> = {
  [Region.Us]: Currency.Usd,
  [Region.Ca]: Currency.Cad,
  [Region.Gb]: Currency.Gbp,
};

export const getCurrencyFromLocale = (locale: string): Currency => {
  if (!locale) {
    return Currency.Usd;
  }

  const { country } = parseLocale(locale);

  if (!country) {
    return Currency.Usd;
  }

  return regionCurrencyMap[country as Region] || Currency.Usd;
};

export const deleteCookie = (name, domain) => {
  if (domain) {
    document.cookie = name + "=; Path=/; Domain=" + domain + "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  } else {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
};

// IMPORTANT: third-party cookies have to be white-listed since javascript does
// not have access to them by default. If we see unwanted cookies, we need to
// add them here and remove them manually. I identified these by enabling and
// then disabling marketing cookies. Whatever's left needs to get added here.
export const deleteThirdPartyCookies = () => {
  deleteCookie("TDCPM", ".adsrvr.org");
  deleteCookie("TDID", ".adsrvr.org");
  deleteCookie("X-AB", "sc-static.net");
  deleteCookie("_pinterest_ct_ua", ".ct.pinterest.com");
  deleteCookie("_ttp", ".tiktok.com");
  deleteCookie("ar_debug", ".pinterest.com");
  deleteCookie("sc_at", ".snapchat.com");
};

// IMPORTANT: third-party storage usage must be cleared when we disable
// marketing tracking.
export const deleteThirdPartyLocalStorage = () => {
  Object.keys(localStorage).forEach((key) => {
    // exact matches
    if (["branch_session_first", "u_sclid", "u_sclid_r", "estTestMode", "_pin_unauth_ls"].includes(key)) {
      localStorage.removeItem(key);
    }
    // pattern matches
    else if (key.startsWith("ab.storage.")) {
      localStorage.removeItem(key);
    }
  });
};

export const deleteAllCookiesAndTracking = () => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookieName = cookies[i].split("=")[0];
    deleteCookie(cookieName, null);
  }
  deleteThirdPartyCookies();
  deleteThirdPartyLocalStorage();
};

export async function copyTextToClipboard(text: string) {
  if (!!navigator?.clipboard) {
    await navigator.clipboard.writeText(text);
  }
}

export {
  bind,
  centsToDecimal,
  currentCookieWeek,
  dedupeObjects,
  dispatchLocalEvent,
  ensureOk,
  fetchLocal,
  filterObject,
  flatMap,
  formatError,
  groupBy,
  isAndroid,
  isIOS,
  isMTC,
  noScroll,
  objectValues,
  openAppStorePage,
  openPlayStorePage,
  pad,
  round,
  scrollToTop,
  sortStoreNames,
  storeLocal,
  sum,
  isMobile,
};
