import Text from "@/components/v2/atoms/Text/Text";
import { ImagesV2 } from "@/public/images/all";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Button from "../../molecules/Button/Button";
import Modal from "../../molecules/Modal/Modal";

interface OutOfRangeDeliveryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
}

export default function OutOfRangeDeliveryModal({ isOpen, onClose, onClick }: OutOfRangeDeliveryModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      variant="secondaryNoDivider"
      isOpen={isOpen}
      onClose={onClose}
      className="!bg-primary-light !w-auto !min-w-none md:!min-w-0 lg:!min-w-none md:!p-10"
    >
      <div
        className={classNames(
          "flex flex-col my-auto sm:max-w-64 md:max-w-full h-full items-center justify-between sm:min-h-[544px]"
        )}
      >
        <div className="flex flex-col justify-center flex-grow">
          <div className="flex flex-col gap-3 items-center justify-between">
            <Image
              src={ImagesV2.illustrationOrderTypeDelivery}
              alt={t("order:delivery")}
              className="max-h-40 object-contain"
            />
            <div className="flex flex-col gap-4 items-center justify-between">
              <Text variant="display3">{t("order:sorry")}</Text>
              <Text variant="body1" className="text-grey-60 text-center max-w-96">
                {t("order:out_of_range_delivery_message")}
              </Text>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-[15px] items-center">
          <Button className="w-full capitalize" onClick={onClick}>
            {t("order:try_different_address")}
          </Button>
          <Button variant="secondary" className="w-full capitalize" onClick={onClose}>
            {t("order:start_pickup_order")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
