import {
  type ApolloCache,
  type DefaultContext,
  type DocumentNode,
  type LazyQueryHookOptions,
  type MutationHookOptions,
  type MutationTuple,
  type OperationVariables,
  type QueryHookOptions,
  type QueryResult,
  type QueryTuple,
  type TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { Service } from "./apollo";

export function useCrumblQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  service: Service,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  return useQuery(query, {
    fetchPolicy: "cache-first",
    ...options,
    context: {
      ...options?.context,
      service,
    },
  });
}

export function useLazyCrumblQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  service: Service,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> {
  return useLazyQuery(query, {
    fetchPolicy: "cache-first",
    ...options,
    context: {
      ...options?.context,
      service,
    },
  });
}

export function useCrumblMutation<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  service: Service,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext>,
): MutationTuple<TData, TVariables, TContext, TCache> {
  // @ts-ignore
  return useMutation(mutation, {
    ...options,
    // @ts-ignore
    context: {
      ...options?.context,
      service,
    },
  });
}

const createQuery =
  (service: Service) =>
  <TData = any, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
  ): QueryResult<TData, TVariables> =>
    useCrumblQuery(service, query, options);

const createLazyQuery =
  (service: Service) =>
  <TData = any, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
  ): QueryTuple<TData, TVariables> =>
    useLazyCrumblQuery(service, query, options);

const createMutation =
  (service: Service) =>
  <
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>,
  >(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables, TContext>,
  ): MutationTuple<TData, TVariables, TContext, TCache> =>
    useCrumblMutation(service, query, options);

export const useAnalyticsQuery = createQuery(Service.analytics);
export const useLazyAnalyticsQuery = createLazyQuery(Service.analytics);

export const useAuthQuery = createQuery(Service.auth);
export const useLazyAuthQuery = createLazyQuery(Service.auth);
export const useAuthMutation = createMutation(Service.auth);

export const useBackendQuery = createQuery(Service.backend);
export const useLazyBackendQuery = createLazyQuery(Service.backend);
export const useBackendMutation = createMutation(Service.backend);

export const useCustomerQuery = createQuery(Service.customer);
export const useLazyCustomerQuery = createLazyQuery(Service.customer);
export const useCustomerMutation = createMutation(Service.customer);

export const useInternalQuery = createQuery(Service.internal);
export const useLazyInternalQuery = createLazyQuery(Service.internal);
export const useInternalMutation = createMutation(Service.internal);

export const usePOSQuery = createQuery(Service.pos);
export const useLazyPOSQuery = createLazyQuery(Service.pos);
export const usePOSMutation = createMutation(Service.pos);

export const useAPIQuery = createQuery(Service.api);
export const useLazyAPIQuery = createLazyQuery(Service.api);
export const useAPIMutation = createMutation(Service.api);
