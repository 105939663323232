export enum Currency {
  Usd = "USD",
  Gbp = "GBP",
  Cad = "CAD",
  Mxn = "MXN",
  Eur = "EUR",
  Jpy = "JPY",
  Rub = "RUB",
  Aud = "AUD",
  Brl = "BRL",
  Hkd = "HKD",
  Krw = "KRW",
  Nzd = "NZD",
  Points = "POINTS"
}
