import type { SVGProps } from "react";
const IconLocationPinRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 2.25c-4.825 0-8.75 3.925-8.75 8.75 0 5.118 4.697 8.22 7.805 10.273l.529.351a.75.75 0 0 0 .832 0l.529-.351C16.053 19.22 20.75 16.118 20.75 11c0-4.825-3.925-8.75-8.75-8.75m.119 17.771-.119.08-.119-.08C8.871 18.033 4.75 15.311 4.75 11c0-3.998 3.252-7.25 7.25-7.25s7.25 3.252 7.25 7.25c0 4.311-4.122 7.034-7.131 9.021M12 7.75A3.254 3.254 0 0 0 8.75 11 3.254 3.254 0 0 0 12 14.25 3.254 3.254 0 0 0 15.25 11 3.254 3.254 0 0 0 12 7.75m0 5c-.965 0-1.75-.785-1.75-1.75s.785-1.75 1.75-1.75 1.75.785 1.75 1.75-.785 1.75-1.75 1.75"
    />
  </svg>
);
export default IconLocationPinRegular;
