import type { SVGProps } from "react";
const IconExclamationCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM11.25 7.5C11.25 7.086 11.586 6.75 12 6.75C12.414 6.75 12.75 7.086 12.75 7.5V12.071C12.75 12.485 12.414 12.821 12 12.821C11.586 12.821 11.25 12.485 11.25 12.071V7.5ZM12.02 16.5C11.468 16.5 11.0149 16.052 11.0149 15.5C11.0149 14.948 11.458 14.5 12.01 14.5H12.02C12.573 14.5 13.02 14.948 13.02 15.5C13.02 16.052 12.572 16.5 12.02 16.5Z"
      fill="currentColor"
    />
  </svg>
);
export default IconExclamationCircleFilled;
