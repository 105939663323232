import { theme } from "@/tailwind.config";
import { createContext, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import IconCheck from "../../atoms/Icons/Check";

/**
 * how to use in any component:
 
  import { useToastContext } from "@/components/v2/contexts/ToastContext/ToastContext";

  setToast.success(t("copy_to_clipboard_success"));
  or
  setToast.error(t("error_message"));
  
*/

export type ToastContextType = {
  setToast: {
    success: (toastMessage: string) => void;
    error: (toastMessage: string) => void;
  };
};

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastContextProvider = ({ children }) => {
  const setToast = toast;

  return (
    <ToastContext.Provider value={{ setToast }}>
      <>
        {children}
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3000,

            // Default options for specific types
            success: {
              duration: 3000,
              style: {
                background: theme.extend.colors.primary.light,
                color: "#000"
              },
              icon: <IconCheck />
            },
            error: {
              duration: 3000,
              style: {
                background: theme.extend.colors.primary.light,
                color: "#000"
              }
            }
          }}
        />
      </>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToastContext must be used within ToastContextProvider");
  }

  return context;
};
