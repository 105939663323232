import type { SVGProps } from "react";
const IconCalendarEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 81 80" {...props}>
    <path
      fill="currentColor"
      d="M60.499 13.332h-5V9.999a1.667 1.667 0 0 0-3.334 0v3.333H28.832V9.999a1.667 1.667 0 0 0-3.333 0v3.333h-5c-7.634 0-11.667 4.033-11.667 11.667v35c0 7.633 4.033 11.666 11.667 11.666h40c7.633 0 11.666-4.033 11.666-11.666v-35c0-7.634-4.033-11.667-11.666-11.667m-40 3.333h5V20a1.667 1.667 0 0 0 3.333 0v-3.334h23.333V20a1.667 1.667 0 0 0 3.334 0v-3.334h5c5.763 0 8.333 2.57 8.333 8.334v3.333H12.165v-3.333c0-5.764 2.57-8.334 8.334-8.334m40 51.667h-40c-5.764 0-8.334-2.57-8.334-8.333V31.665h56.667V60c0 5.763-2.57 8.333-8.333 8.333M50.272 37.136a4.42 4.42 0 0 0-3.143-1.304 4.42 4.42 0 0 0-3.15 1.31L26.821 54.38c-.31.31-.487.733-.487 1.176V62.5c0 .92.747 1.666 1.667 1.666h6.943c.44 0 .864-.173 1.177-.486l17.24-17.16a4.41 4.41 0 0 0 1.31-3.144 4.42 4.42 0 0 0-1.303-3.146zM34.255 60.832h-4.59v-4.59l11.864-11.92 4.646 4.646zm16.75-16.673-2.47 2.46-4.653-4.653 2.457-2.47c.416-.417 1.15-.424 1.573 0l3.097 3.093a1.105 1.105 0 0 1-.003 1.57"
    />
  </svg>
);
export default IconCalendarEdit;
